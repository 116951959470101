/*--------------------------------------------------- FORM CSS START ---------------------------------------------------*/
.form-page .formLeft {
    background-image: url("../src/assets/images/form-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.form-page .formLeft .left-text {
    padding: 50px 50px;
}

.form-page .formLeft .left-text .text-bottom {
    bottom: 7%;
}

.form-page .formLeft .left-text h2 {
    font-size: 87px;
    letter-spacing: 2px;
    font-weight: 500;
}

.form-page .formLeft .left-text p {
    font-size: 20px;
}

.formRight img.formtop {
    width: 195px;
    height: 218px;
    right: 15px;
    top: 15px;
}

.formRight img.formbottom {
    width: 126px;
    height: 94px;
    left: 15%;
}

.formRight .signupForm {
    padding: 70px 50px;
}

.form-head h2 {
    font-size: 36px;
    z-index: 9999;
}

.form-head h6 {
    font-size: 18px;
    z-index: 9999;
    font-weight: 400 !important;
}

.assistlogo {
    top: 30px;
    left: 30px;
}

.email-input img {
    left: 8px;
    bottom: 8px;
}

.main-form {
    width: 50%;
}

.icon-input img {
    top: 18px;
    left: 15px;
}

.icon-input input {
    background: #EDEEEE;
    padding-left: 50px;
    border: 1px solid #EDEEEE !important;
}

.eye-icons {
    right: 15px;
    top: 18px;
}

.form-control:focus {
    box-shadow: none !important;
    background: #fff !important;
    border: 1px solid #EDEEEE !important;
}

.main-form .sub-button {
    font-size: 20px;
    font-weight: 500;
    background: #004FC5;
}

.main-form .or-area span {
    height: 1px;
    background: #EDEEEE;
}

.main-form .account-text h4 {
    font-size: 20px;
    color: #8F9193;
    font-weight: 500;
}

.main-form .account-text h4 a {
    color: #004FC5;
}

.forgot-text a {
    font-weight: 500;
}

.dropdown-profile ul.dropdown-menu {
    padding: 13px !important;
}

.dropdown-profile ul.dropdown-menu li {
    padding: 5px 10px !important;
    cursor: pointer;
    color: #000;
}

.dropdown-profile ul.dropdown-menu li:hover {
    background: #e4eaf1;
}

.dropdown-profile img.userpicture {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
}

/* ---------------------------------------- SUPER ADMIN START ----------------------------------------------*/
/* ---------------------------------------- FORM CSS START ---------------------------------------------------*/
.superadmin-login {
    background-image: url("../src/assets/images/form-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
}

.superadmin-login .up-logo {
    position: absolute;
    top: 5%;
    left: 3%;
}

.form-head h2 {
    font-size: 36px;
    z-index: 9999;
}

.form-head h6 {
    font-size: 18px;
    z-index: 9999;
    color: #9B9D9F;
}

.email-input img {
    left: 8px;
    bottom: 8px;
}

.main-saform {
    border-radius: 40px;
}

.icon-input img {
    top: 18px;
    left: 15px;
}

.icon-input input {
    background: #EDEEEE;
    padding-left: 50px;
    border: 1px solid #EDEEEE !important;
}

.eye-icons {
    right: 15px;
    top: 18px;
}

.form-control:focus {
    box-shadow: none;
    background: #fff;
    border: 1px solid #EDEEEE;
}

.main-saform {
    border-radius: 40px;
    width: fit-content !important;
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translate(0%, -50%) !important;
}

.main-saform .sub-button {
    font-size: 20px;
    font-weight: 500;
    background: #004FC5;
}

.main-saform label {
    font-size: 18px;
    color: #9B9D9F;
}

.icon-input span {
    top: 15px;
    right: 15px;
}

.logoutmodal {
    background: #000000a3;
}

/* ---------------------------------------- SUPERADMIN FORM END ---------------------------------------- */
/* ---------------------------------------- FORM CSS END ---------------------------------------- */
/* ---------------------------------------- SIDEBAR START ----------------------------------------*/
.sidebar-main {
    background-image: url("../src/assets/images/sidebar-bg.jpg");
    width: 118px;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px;
    display: block;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.sidebar-main ul {
    padding-left: 0px !important;
}

.sidebar-main .siderbar-nav li.setting {
    position: absolute !important;
    /* padding-left: 20px; */
    left: 0;
    right: 0;
    bottom: 20px;
}

.sidebar-main .siderbar-nav h5 {
    color: #91a3c3;
    font-size: 14px;
    font-weight: 400;
}

.sidebar-main .siderbar-nav li {
    /* padding-top: 15px; */
    /* padding-left: 15px; */
    /* padding-right: 40px; */
    padding-bottom: 0px;
    text-align: center;
    list-style: none;
    margin: 20px 0px 0px 0px !important;
}

.sidebar-main .siderbar-nav li:hover {
    background: none !important;
    border-radius: 20px 0px 0px 20px;
    transition: 0.2s;
}

.sidebar-main .siderbar-nav li:hover a h5 {
    color: #fff !important;
}

.sidebar-main .siderbar-nav li:hover svg {
    fill: #fff !important;
}

.sidebar-main .siderbar-nav li.active {
    background: none;
    border-radius: 20px 0px 0px 20px;
    transition: 0.2s;
}

.sidebar-main .siderbar-nav li.active a h5 {
    color: #fff !important;
}

.sidebar-main .siderbar-nav li.active svg {
    fill: #ffffff !important;
}

.sidebar-main .siderbar-nav li.active .border-li {
    display: block;
    position: absolute;
    left: -24px;
    top: 32px;
    width: 3px;
    height: 38px;
    background: #004fc5;
    border-radius: 0px 10px 10px 0px;
    transition: 0.5s;
}

.sidebar-main .siderbar-nav li svg {
    fill: #91a3c3;
}

.border-li {
    display: none;
    position: absolute;
    left: -24px;
    top: 0;
    bottom: 0;
    width: 3px;
    height: 80%;
    background: #fff;
    border-radius: 0px 10px 10px 0px;
    transition: 0.5s;
}

.logo-header img {
    width: 40px;
}

.sidebar-heading {
    border-bottom: 1px solid #597EBA;
}

.sidebar-main .siderbar-nav li:hover .border-li {
    display: block;
}

.sidebar-main.sd {
    display: none;
    transition: 0.3s;
}

.header-main.dd {
    margin-left: 0px;
    transition: 0.3s;
    display: flex !important;
}

/* SIDEBAR END */
/* HEADER START */
.header-main {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px;
    position: fixed !important;
    top: 0;
    left: 118px;
    right: 0px;
    z-index: 1000;
    height: 70px;
}

.user_profile {
    width: 45px;
    max-width: 45px;
    height: 45px;
    border-radius: 100px;
    object-fit: cover;
}

.header-main i {
    display: none;
}

.header-main .left-header h2 {
    font-size: 24px;
    font-weight: 600;
}

.header-main .right-header .bellicon span {
    width: 10px;
    height: 10px;
    background: #F93333;
}

.header-main .right-header .messageicon span {
    width: 10px;
    height: 10px;
    background: #F93333;
}

.right-header hr {
    margin: 0px 15px;
    height: 10px;
    width: 1px;
    display: block;
    padding: 10px 1px;
    background: #C7C8C9;
    opacity: 1;
    border: none;
}

.profile .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .5em solid !important;
    border-right: .5em solid transparent !important;
    border-bottom: 0;
    border-left: .5em solid transparent !important;
}

.profile .user-name h4 {
    font-size: 18px;
    font-weight: 700;
}

.profile .user-name p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 0px;
}

.profile .dropdown-menu.show {
    margin-top: 15px !important;
}

.header-main .navbar-collapse.show {
    background: #fff;
}

.navbar-toggler-icon {
    background-image: url("../src/assets/images/user.png") !important;
    object-fit: contain !important;
    width: 16px !important;
    height: 30px !important;
}

.navbar-toggler:focus {
    box-shadow: none;
}

.navbar-toggler {
    border: none !important;
}

.dropdown-profile .dropdown-toggle::after {
    background-image: url("../src/assets/images/drop_down.png");
    width: 12px;
    height: 12px;
    object-fit: contain;
    background-repeat: no-repeat;
    border: none;
    margin-left: 12px;
}

.header-main .drop-profile {
    width: 300px;
    right: 0px;
    top: 0;
    left: auto !important;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 6px 5px 0px rgba(0, 79, 197, 0.29);
}

.bg-gray-li {
    background: #EDEEEE;
    border-radius: 7px;
}

.header-main .drop-profile li.edit:hover,
.header-main .drop-profile li.edit:hover div.dropdown-item,
.drop-profile li:hover div.dropdown-item {
    background: #1C8B15;
    color: #fff;
    transition: 0.3s;
}

.header-main .drop-profile li.change:hover,
.header-main .drop-profile li.change:hover div.dropdown-item,
.drop-profile li:hover div.dropdown-item {
    background: #004FC5;
    color: #fff;
    transition: 0.3s;
}

.header-main .drop-profile li.logout:hover,
.header-main .drop-profile li.logout:hover div.dropdown-item,
.drop-profile li:hover div.dropdown-item {
    background: #F93333;
    color: #fff;
    transition: 0.3s;
}

.header-main .drop-profile li.edit:hover>svg,
.header-main .drop-profile li.change:hover>svg,
.header-main .drop-profile li.logout:hover>svg {
    fill: #fff !important;
}

/* SEARCH FIELD START */
.search_field {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    display: none;
}

.search_field form {
    height: 100%;
}

.search_field .search_bar {
    display: flex;
    align-items: center;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    height: 100%;
    padding-left: 14px;
}

.search_field .search_bar input.btn {
    margin-left: 10px;
    font-size: 18px;
    background: #2e59a3;
    color: #fff;
}

.search_field .search_bar input[type="search"] {
    padding: 10px;
    background: #EDEEEE;
}

.search_field .search_bar .close_search {
    margin-left: 15px;
    color: #fff;
    font-size: 20px;
    padding: 20px;
    border-left: 1px solid #2e59a3;
    height: 100%;
    display: flex;
    align-items: center;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    cursor: pointer;
}

/* SEARCH FIELD END */
/* MODAL CSS START */
.modal-content {
    border-radius: 20px;
}

.profile-modal .modal-content {
    border-radius: 20px;
    padding: 10px;
}

.profile-modal h4 {
    font-size: 20px;
    font-weight: 600;
}

.btn-close {
    background-image: url("../src/assets/images/close-modal.svg") !important;
    opacity: 1 !important;
    font-size: 26px;
}

.profile-modal p {
    font-size: 16px;
    color: #9B9D9F;
    margin-top: 4px !important;
    line-height: 21px;
    display: block !important;
}

.profile-modal .btn-groups {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
}

.profile-modal .btn-groups .btn {
    width: fit-content;
    border: 1px solid #ccc;
    border-radius: 18px;
    padding: 20px 25px;
    font-size: 20px;
    font-weight: 600;
}

.profile-modal .btn-groups .btn:hover {
    background: #004FC5;
    color: #fff;
    transition: 0.4s;
}

.profile-modal .btn-groups .btn.save-btn {
    background: #004FC5;
    color: #fff;
}

.profile-modal .btn-groups .btn.save-btn:hover {
    background: #000000;
    color: #fff;
}

.avatar-upload {
    position: relative;
    max-width: 150px;
}

.avatar-upload .avatar-edit {
    position: absolute;
    right: 25px;
    z-index: 1;
    bottom: 40px;
}

.avatar-upload .avatar-edit input {
    display: none;
}

.avatar-upload .avatar-edit input+label {
    width: 34px;
}

.avatar-upload .avatar-edit input+label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
    content: "";
    background-image: url("../src/assets/images/user-upload.png");
    color: #757575;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    width: 42px;
    height: 42px;
    object-fit: contain;
}

.avatar-upload .avatar-preview {
    width: 122px;
    height: 122px;
    position: relative;
    border-radius: 100%;
}

.avatar-upload .avatar-preview>div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 3px solid #004FC5;
}

/* MODAL CSS END */
/* HEADER END */
.sidebar-main.show-sidebar {
    left: 0;
    z-index: 999999;
}

.overlay-sidebar {
    display: none;
}

.overlay-sidebar.show-sidebar {
    display: block;
    width: 100%;
    background: #000c;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}

.cancel-menu img {
    width: 25px;
    height: 25px;
    fill: #fff;
    border-radius: 5px;
    position: absolute;
    right: 15px;
    top: 15px;
    filter: invert(1);
    object-fit: contain;
}

#menu-toggle {
    cursor: pointer;
}

/* MAIN CONTENT START */
/* ---------------------------------------- DASHBOARD CSS START ---------------------------------------- */
.list-group-item {
    border: none;
    padding: 20px 30px;
}

.list-group-item.active {
    background-color: transparent;
    color: var(--main-text-color);
    font-weight: bold;
    border: none;
}

.right_section {
    position: fixed;
    bottom: 0;
    left: 118px;
    top: 70px;
    right: 0;
    overflow-y: auto;
    transition: 0.3s;
    -webkit-transition: 0.3s;
    padding: 0px 20px 20px 20px;
}

.right_section .inside span {
    font-weight: 500;
}

.right_section .inside {
    padding: 40px;
    border-radius: 30px;
}

.green-content {
    color: #1C8B15;
}

.red-content {
    color: #F93333;
}

.right_section .grad-one {
    background: #BED4F0;
}

.grad-two {
    background: #FCECCD;
}

.grad-three {
    background: #FDC8C8;
}

.grad-four {
    background: #C2E0C1;
}

.indic h4 {
    font-size: 36px;
}

.grad-one p {
    font-size: 20px;
    color: #065AC4;
    font-weight: 500;
}

.grad-two p {
    font-size: 20px;
    color: #F5BB48;
    font-weight: 500;
    margin-bottom: 0px;
}

.grad-three p {
    font-size: 20px;
    color: #F93333;
    font-weight: 500;
}

.grad-four p {
    font-size: 20px;
    color: #1C8B15;
    font-weight: 500;
}

.right_section .grad-one .inside-one {
    background: red;
}

/*---------------------------------------- TASKS START ----------------------------------------*/
.task-boxes .task-inside {
    border: 3px dashed #E3E5E8;
    border-radius: 20px;
}

.task-boxes .task-inside h6 {
    font-weight: 500;
}

.task-white {
    width: 100%;
    padding: 18px;
    background: #fff;
    border-radius: 20px;
}

.left-name {
    background: #000;
    border-radius: 50px;
    width: fit-content;
    padding: 2px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    height: 30px;
}

.left-name img {
    width: 26px;
    height: 26px;
    border-radius: 100px;
}

.left-name p {
    font-size: 14px;
    padding: 0px 15px 0px 10px;
}

.file-edit button {
    background: #EDEDEE;
    border-radius: 8px;
    border: none;
    padding: 10px;
    color: #A6A7A9;
    font-size: 14px;
    height: 30px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.file-delete button {
    background: #EDEDEE;
    border-radius: 8px;
    border: none;
    padding: 10px;
    color: #A6A7A9;
    font-size: 14px;
}

.box-content h3 {
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0px;
}

.left-task-footer {
    background: #fff;
    border-radius: 50px;
    width: fit-content;
    padding: 2px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    /* border: 1px solid #E3E5E8; */
    height: 30px;
}

.left-task-footer img {
    width: 26px;
    max-width: 26px;
}

.left-task-footer p {
    font-size: 14px;
    font-weight: 500;
    padding: 0px 15px 0px 10px;
    margin-bottom: 0px;
}

.task-box-footer button {
    border-radius: 8px;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    height: 30px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.status-Open {
    background: #37ba07;
    color: #fff;
}

.status-Completed {
    background: #1C8B15;
    color: #fff;
}

.status-Incomplete {
    background: #F93333;
    color: #fff;
}

.status-InProgress {
    background: #004FC5;
    color: #fff;
}

/*---------------------------------------- TASKS END ----------------------------------------*/
/*---------------------------------------- TABLE CSS START ----------------------------------------*/
table {
    border-collapse: separate;
    border-spacing: 0;
}

table tr td {
    border-bottom: 2px solid #fff;
}

tr:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

tr:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.table-area .table-heading h2 {
    font-size: 24px;
    font-weight: 600;
}

.table-area .table_part thead th {
    font-weight: 600;
}

.table-area .table_part tbody tr td {
    white-space: nowrap;
}

.table-area .table_part tbody tr td div {
    white-space: normal;
}

.table-area .table_part tbody tr {
    background: #EDEEEE;
    border-radius: 5px;
}

.table-area .table_part tbody tr:hover {
    background: #fff;
    transition: 0.3s;
    box-shadow: 0px 6px 5px 0px rgba(0, 79, 197, 0.29);
    -webkit-box-shadow: 0px 6px 5px 0px rgba(0, 79, 197, 0.29);
    -moz-box-shadow: 0px 6px 5px 0px rgba(0, 79, 197, 0.29);
    transform: scale(1.001);
}

.table-area .table_part tbody tr td button::after {
    display: none !important;
    border: none !important;
}

.table-area .table_part tbody tr td .dropdown-toggle {
    border: none;
}

/*---------------------------------------- TABLE CSS END ----------------------------------------*/
/*---------------------------------------- AVATARS START ----------------------------------------*/
.avatar {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    background-color: #2c303a;
    border: 2px solid #fff;
}

.avatar img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.avatar-group {
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.avatar-group.rtl {
    direction: rtl;
}

.avatar-group.rtl .avatar:hover:not(:last-of-type) {
    transform: translate(10px);
}

.avatar-group .avatar {
    margin-left: -20px;
    transition: transform 0.3s ease;
    cursor: pointer;
}

.avatar-group .avatar:hover:not(:last-of-type) {
    transform: translate(-10px);
}

.avatar-group .hidden-avatars {
    margin-left: 3px;
    margin-right: 23px;
    color: #000;
}

/*---------------------------------------- AVATARS END ----------------------------------------*/
/*---------------------------------------- MAIN CONTENT END ----------------------------------------*/
/*---------------------------------------- TENANT PAGE CSS START ----------------------------------------*/
.tenants table thead tr th {
    vertical-align: middle;
}

.tenants table input[type="checkbox"] {
    width: 18px;
    height: 18px;
}

.tenants table tbody tr td {
    vertical-align: middle;
}

.tenants table tbody tr td span.active {
    width: 6px;
    height: 6px;
    display: block;
    background: #1C8B15;
    padding: 4px 8px;
    border-radius: 3px;
}

span.active {
    width: 6px;
    height: 6px;
    background: #1d8b1548 !important;
    color: #1C8B15 !important;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 14px;
}

span.inactive {
    width: 6px;
    height: 6px;
    background-color: #f933333d !important;
    color: #F93333 !important;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 14px;
}

span.stopped {
    width: 6px;
    height: 6px;
    background-color: #CCDCF4 !important;
    color: #358cc5 !important;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 14px;
}

.tenants table tbody tr td span.inactive {
    width: 6px;
    height: 6px;
    background: #F93333;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 14px;
}

/*---------------------------------------- TENANT PAGE CSS END ----------------------------------------*/
/*---------------------------------------- CLIENTS CSS START ----------------------------------------*/
.client-detail-head {
    position: relative;
    z-index: 99;
}

.clients-area .client-tabs .nav-link {
    color: #9b9d9f;
    border-radius: 50px;
    border: none;
    height: 46px;
    padding-left: 22px;
    padding-right: 22px;
}

.clients-area .client-tabs .nav-link:hover {
    background: #000;
    color: #fff;
    transition: 0.4s;
}

.clients-area .client-tabs .nav-link.active {
    background: #000000;
    color: #ffffff;
}

.clients-area .search-bar i {
    top: 12px;
    color: #B1B3B5;
    left: 5px;
}

.clients-area .search-bar input {
    padding-left: 40px;
    background: #F1F1F1;
    border: none;
    height: 40px;
}

input::placeholder {
    color: #747475 !important;
    font-size: 14px !important;
    font-weight: 400;
}

input.task_input::placeholder {
    color: #747475 !important;
    font-size: 24px !important;
    font-weight: 400;
}

span.b-lr {
    height: 25px;
    width: 1px;
    background: #B1B3B5;
}

.btn-clients-filter button:hover,
.upload-btn:hover {
    background: #000;
    border-radius: 8px;
    color: #fff;
}

.btn-clients-filter button:hover svg {
    fill: #fff;
}

.btn-active-inactive {
    border: 1px solid #D9D9D9;
    width: fit-content;
    border-radius: 10px;
    padding: 3px;
    height: 40px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.btn-active-inactive button {
    background: none;
    border: none;
    color: #9B9D9F;
    font-size: 14px;
    padding: 7px 12px;
}

.btn-active-inactive button svg {
    margin-right: 0px;
}

.btn-active-inactive button:hover {
    background: #000;
    border-radius: 8px;
    color: #fff;
}

.btn-active-inactive button.upload-btn {
    border-right: 1px solid #C7C8C9;
    border-left: 1px solid #C7C8C9;
    padding: 0px 12px;
    height: 46px;
}

.btn_dark {
    border: none;
    background: #DCDCDC !important;
    color: #000000;
    border: 1px solid #DCDCDC;
    font-size: 14px;
    border-radius: 10px;
    padding: 5px 10px !important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.add-new {
    border: none;
    background: #004FC5 !important;
    color: #fff;
    border: 1px solid #004FC5;
    font-size: 16px;
    border-radius: 10px;
    height: 40px;
    padding: 7px 10px !important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.add-yes {
    border: none;
    background: #004FC5;
    color: #fff;
    font-size: 16px;
    border-radius: 10px;
    height: 40px;
    padding: 7px 20px !important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.add-no {
    border: 1px solid #D9D9D9;
    background: transparent;
    color: #000;
    font-size: 16px;
    border-radius: 10px;
    height: 40px;
    padding: 7px 20px !important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.transbtn {
    border: 1px solid #004FC5;
    background: transparent;
    color: #004FC5;
    font-size: 16px;
    border-radius: 10px;
    height: 40px;
    padding: 7px 10px !important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.transbtn:hover {
    border: 1px solid #004FC5;
    background: #004FC5;
    color: #fff;
    transition: 0.5s;
}

button.add-new i {
    background-color: #fff;
    border-radius: 100px;
    padding: 5px;
    color: #004FC5;
    width: 20px;
    height: 20px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

/*---------------------------------------- CLIENTS CSS END ----------------------------------------*/
/*---------------------------------------- ADD MODAL CSS START ----------------------------------------*/
#add-new .add-form a.bg-blue-light {
    background: #E5EDF9;
    border-radius: 20px;
    padding: 50px;
}

.modal.add-new {
    background: transparent;
}

/*---------------------------------------- ADD MODAL CSS END ----------------------------------------*/
/*---------------------------------------- TABLE CSS START ----------------------------------------*/
table {
    border-collapse: separate;
    border-spacing: 0px 10px;
}

.table-heading h2 {
    font-size: 24px;
    font-weight: 600;
}

.table_part thead th {
    font-weight: 400;
    white-space: nowrap;
    color: #747475;
    cursor: auto !important;
}

.table_part tbody tr td {
    /* white-space: nowrap; */
    white-space: normal;
    vertical-align: middle;
    width: max-content;
    text-overflow: ellipsis;
}

.table_part tbody tr td div {
    white-space: normal;
    gap: 8px;
}

.table_part tbody tr {
    background: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 7%) 0px 2px 20px;
}

.table_part tbody tr td button::after {
    display: none !important;
    border: none !important;
}

.table_part tbody tr td .dropdown-toggle {
    border: none;
}

.table_part tbody tr td label {
    white-space: normal;
    color: #000;
    margin: 0px !important;
}

.table_part ul {
    padding: 15px;
    border: none;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.table_part ul li a {
    padding: 0px;
    font-weight: 500;
}

.table_part ul hr {
    margin: 12px 0px;
    color: #0000001A;
    opacity: 1;
}

.table_part ul a:hover {
    background: none;
}

.table_part ul.pagination {
    box-shadow: none;
}

.status.active-st {
    padding: 3px 10px;
    border-radius: 8px;
    background: #D1E8D0;
    color: #1C8B15;
    width: fit-content;
    text-align: center;
    font-size: 14px;
}

.status.maybe-st {
    padding: 3px 10px;
    border-radius: 8px;
    background: #FFEECC;
    color: #FFAA00;
    width: fit-content;
    text-align: center;
    font-size: 14px;
}

.status.inactive-st {
    padding: 3px 10px;
    border-radius: 8px;
    background: #FED6D6;
    color: #F93333;
    text-align: center;
    width: fit-content;
    font-size: 14px;
}

/*---------------------------------------- TABLE CSS END ----------------------------------------*/

/*---------------------------------------- MODAL CSS START ----------------------------------------*/
#add-new .add-form a.bg-blue-light {
    background: #E5EDF9;
    border-radius: 20px;
    padding: 50px;
}

#add-new .modal-dialog {
    max-width: var(--bs-modal-width);
}

#add-new .modal-dialog .fa-times-circle:before {
    color: #000;
    font-size: 24px;
}

/*---------------------------------------- MODAL CSS END ----------------------------------------*/
/*---------------------------------------- MODAL CSS START ----------------------------------------*/
.addd-new .add-form .bg-blue-light {
    background: #E5EDF9;
    border-radius: 20px;
    padding: 50px;
}

.addd-new .modal-dialog {
    max-width: var(--bs-modal-width);
}

.addd-new .modal-dialog .fa-times-circle:before {
    color: #000;
    font-size: 24px;
}

/*---------------------------------------- MODAL CSS END ----------------------------------------*/
/*---------------------------------------- INDIVIDUAL PAGE CSS START ----------------------------------------*/
.main-form-area {
    border-radius: 20px;
}

.main-form-area .genders {
    border-radius: 10px;
    background-color: #EDEEEE !important;
    width: fit-content;
    height: 70px;
}

.genders .male,
.genders .female,
.genders .trans {
    padding: 15px;
    cursor: pointer;
}

.genders span {
    height: 25px;
    width: 1px;
    background: #B1B3B5;
}

.genders .male:hover,
.genders .female:hover,
.genders .trans:hover {
    background: #000;
    border-radius: 10px;
}

.upload-input input[type="file" i] {
    opacity: 0;
}

/* .upload-input {
    border: 2.5px dashed #212529;
    border-radius: 10px;
    padding: 40px 50px;
    height: 200px;
    align-content: center;
  } */

.upload-input {
    border: 2.5px dashed #212529;
    border-radius: 10px;
    padding: 40px 50px;
    height: fit-content;
    align-content: center;
    max-height: -moz-fit-content;
    max-height: fit-content;
    min-height: -moz-fit-content;
    min-height: 200px;
}

.upload-main-wrapper {
    width: fit-content;
    margin: 0 auto;
}

#file-upload-name {
    margin: 4px 0 0 0;
    font-size: 12px;
}

.upload-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    background-color: #004FC5;
    padding: 15px 10px;
    border-radius: 10px;
    overflow: hidden;
    height: 40px;
    transition: 0.2s linear all;
    color: #ffffff;
    font-size: 16px;
    width: 124px;
    margin: 0 auto;
}

.upload-wrapper input[type="file"] {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 99;
}

.upload-wrapper i,
.file-upload-text {
    position: relative;
    z-index: 999;
}

.upload-main-wrapper h4 {
    font-weight: 600;
}

.individual-footer .btn-groups {
    display: flex;
    display: -webkit-flex;
    justify-content: flex-end;
}

.individual-footer .btn-groups .btn {
    width: fit-content;
    border: 1px solid #ccc;
    border-radius: 18px;
    padding: 12px 45px;
    font-size: 20px;
    font-weight: 600;
}

.individual-footer .btn-groups .btn:hover {
    background: #004FC5;
    color: #fff;
    transition: 0.4s;
}

.individual-footer .btn-groups .btn.save-btn {
    background: #004FC5;
    color: #fff;
}

.individual-footer .btn-groups .btn.save-btn:hover {
    background: #000000;
    color: #fff;
}

.intl-tel-input {
    display: block;
}

.intl-tel-input .selected-flag {
    z-index: 4;
}

.intl-tel-input .country-list {
    z-index: 5;
}

.intl-tel-input input,
.intl-tel-input input[type=text],
.intl-tel-input input[type=tel] {
    margin-top: 0.5rem !important;
}

/*---------------------------------------- INDIVIDUAL PAGE CSS END ----------------------------------------*/
select.form-input {
    border-radius: 10px !important;
    background-color: #EDEEEE !important;
    padding-left: 20px;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: 100%;
}

select::placeholder {
    color: #747475 !important;
    font-size: 14px !important;
    font-weight: 400;
}

select {
    padding: 10px;
    padding-right: 30px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../src/assets/images/drop_down.png');
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    width: 30px;
    height: 30px;
    border-width: thin;
    margin-right: 15px;
}

/*---------------------------------------- CLIENTS DETAIL CSS START ----------------------------------------*/
.clients-detail-area .client-detail-tabs .nav-link {
    border: 1px solid #9b9d9f;
    color: #9b9d9f;
    border-radius: 50px;
    height: 40px;
    padding-left: 22px;
    padding-right: 22px;
}

.clients-detail-area .client-detail-tabs .nav-link:hover {
    background: #000;
    color: #fff;
    transition: 0.4s;
}

.clients-detail-area .client-detail-tabs .nav-link.active {
    background: #000000;
    color: #ffffff;
}

.clients-detail-area .search-bar i {
    top: 12px;
    color: #B1B3B5;
    left: 5px;
}

.clients-detail-area .search-bar input {
    padding-left: 40px;
    background: #F1F1F1;
    border: none;
    height: 40px;
}

.btn-clients-filter button svg {
    margin-right: 0px;
}

.btn-clients-filter button {
    background: none;
    border: none;
    color: #000000;
    font-size: 16px;
    padding: 5px 10px !important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    gap: 5px;
}

/*---------------------------------------- CLIENTS DETAIL CSS END ----------------------------------------*/
.btn-clients-filter {
    width: fit-content;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 3px;
    height: 40px;
}

.btn-clients-filter button.upload-btn {
    padding: 7px 10px;
}

.home-right button {
    color: #004FC5;
    background: #D5E2F6;
    border: none;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    border-radius: 8px;
    padding: 5px 8px;
    font-size: 14px;
}

/*---------------------------------------- CLIENTS DETAIL CSS END ----------------------------------------*/
/*---------------------------------------- HOME TAB CSS START ----------------------------------------*/
/* #home .prof-inside {
    height: 100%;
  } */

#home hr {
    width: 92%;
    margin: 15px auto !important;
}

.profile-box {
    background-image: url("../src/assets/images/profile-bg.jpg");
    width: 100%;
    height: 287px;
    padding: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}

.write-icon {
    /* background: #fff;
    padding: 8px;
    border-radius: 8px;
    display: fe;
    display: flex;
    align-items: center;
    justify-content: center; */
    position: absolute;
    right: 40px;
    top: -20px;
    /* height: fit-content; */
}

.profile-box .login-box {
    background-color: #6d9ade;
    width: fit-content;
    border-radius: 20px 0px 20px 0px;
    padding: 8px 15px;
}

.profile-box .login-box p {
    color: #fff;
}

.profile-box .login-box span {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 100%;
    display: block;
}

.p-detail-box {
    background: #212529;
    border-radius: 20px;
}

.dp-box {
    width: 66px;
    height: 66px;
    border-radius: 100%;
    object-fit: cover;
    overflow: hidden;
    border: 4px solid #004FC5;
}

.dp-detail h5 {
    color: #fff;
    font-size: 22px;
}

.dp-detail span p {
    color: #889CBB;
    word-break: break-word;
}

span.w-lr {
    height: 25px;
    width: 1px;
    background: #889CBB;
    display: block;
}

.right-prof ul li p {
    color: #889CBB;
}

.about-info .about-info-box p {
    color: #747475;
    text-align: left !important;
}

.about-info .about-info-box h4 {
    color: #000000;
    font-weight: 600;
    text-align: left !important;
}

.task-info .task-info-box p {
    color: #747475;
    text-align: left !important;
}

.task-info .task-info-box h4 {
    color: #000000;
    font-weight: 600;
    line-height: inherit;
    text-align: left !important;
}

.notes-box .notes-info-box p {
    color: #747475;
    text-align: left !important;
}

.notes-box .notes-info-box h4 {
    color: #000000;
    font-weight: 600;
    line-height: inherit;
    text-align: left !important;
}

.notes-box .notes-info-box span {
    width: 10px;
    height: 10px;
    background: #000;
    display: block;
    border-radius: 100%;
    margin-top: 8px !important;
}

.notes-box .notes-info-box p {
    color: #747475;
}

.notes-box .notes-info-box h4 {
    color: #000000;
    font-weight: 600;
    text-align: left !important;
    line-height: inherit;
    text-align: left !important;
}

.linked-account {
    max-height: 300px;
    overflow: scroll;
}

.imp-clients p {
    color: #747475;
    text-align: left !important;
}

.imp-clients h4 {
    color: #000000;
    font-weight: 600;
    line-height: inherit;
    text-align: left !important;
}

.imp-clients li {
    padding: 15px;
    background: #F1F2F3;
    border-radius: 20px;
}

.recent-files li {
    padding: 15px;
    background: #F1F2F3;
    border-radius: 20px;
}

.recent-files h4 {
    color: #000000;
    font-weight: 600;
    line-height: inherit;
    text-align: left !important;
}

.status-client {
    background: #E8D7FD;
    color: #8E3AF5;
    border-radius: 8px;
    padding: 7px 10px;
    font-size: 12px;
    display: block;
    width: fit-content;
    margin-left: auto !important;
}

.status-review {
    background: #FFD5DF;
    color: #FC2F5E;
    border-radius: 8px;
    font-size: 12px;
    padding: 7px;
    display: block;
    width: fit-content;
    margin-left: auto !important;
}

.nostatus {
    background: #EDEDEE;
    color: #A6A7A9;
    border-radius: 8px;
    padding: 7px 10px;
    font-size: 12px;
    display: block;
    width: fit-content;
    margin-left: auto !important;
}

.status-access {
    background: #CCDCF4;
    color: #004FC5;
    border-radius: 8px;
    padding: 7px 10px;
    font-size: 12px;
    display: block;
    width: fit-content;
    margin-left: auto !important;
}

.btn-blue {
    background: #004FC5;
    color: #fff;
    border-radius: 10px;
    padding: 12px 20px;
    border: none;
    font-size: 16px;
    height: 40px;
    border: 1px solid #004FC5;
    display: flex;
    align-items: center;
}

.btn-red {
    background: #dd0606;
    color: #fff;
    border-radius: 10px;
    padding: 12px 20px;
    border: none;
    font-size: 16px;
    height: 40px;
    border: 1px solid #dd0606;
    display: flex;
    align-items: center;
}

.btn-gray {
    background: #cfcfcf;
    color: #000000;
    border-radius: 10px;
    padding: 12px 20px;
    border: none;
    font-size: 16px;
    height: 40px;
    border: 1px solid #cfcfcf;
    display: flex;
    align-items: center;
}

.pin-modal .modal-content {
    height: 250px;
}

.addbox {
    height: 150px;
    border-radius: 15px;
}

.modal-content.h-700 {
    min-height: 700px;
    height: fit-content;
}

.r_client .active-pin {
    background: #000;
}

.r_client .active-pin svg {
    fill: #fff;
}

.r_client .inactive-pin {
    background: #EDEDEE;
}

.r_client .inactive-pin svg {
    fill: #000;
}

/*---------------------------------------- ADVANCE DETAILS MODAL START ----------------------------------------*/
label {
    color: #747475;
}

.select-text p {
    position: absolute;
    top: 20px;
    font-weight: 600;
    left: 12px;
    border-right: 2px solid #C7C8C9;
    padding-right: 10px;
}

.select-text select {
    padding-left: 25% !important;
}

/*---------------------------------------- ADVANCE DETAILS MODAL END ----------------------------------------*/
/*---------------------------------------- DIRECTOR DETAILS MODAL START ----------------------------------------*/
.search-input i {
    position: absolute;
    top: 15px;
    color: #747475;
    left: 10px;
}

.search-input input {
    border: 1px solid #004FC51A;
    border-radius: 10px;
    padding: 10px 15px 10px 35px;
    width: 100%;
}

.search-input input:-ms-input-placeholder {
    color: #747475;
}

/*---------------------------------------- DIRECTOR DETAILS MODAL END ----------------------------------------*/
/*---------------------------------------- UPLOAD MODAL CSS START ----------------------------------------*/
.uploading {
    border: 1.5px solid #DCDCDC;
    width: 100%;
    border-radius: 20px;
    padding: 10px 15px;
}

.uploading h5 {
    color: #000;
    font-weight: 600;
}

.uploading span {
    margin-top: -8px;
}

.uploading span p {
    color: #747475;
}

.uploading hr {
    width: 2px;
    height: 10px;
    background: #DCDCDC;
    margin: 0px 10px;
    opacity: 1;
    border: none;
}

.uploading .progress {
    height: 6px;
    background: #D5E2F6;
    border-radius: 4px;
}

.uploading i {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #747475;
}

/*---------------------------------------- UPLOAD MODAL CSS END ----------------------------------------*/
/*---------------------------------------- HOME TAB CSS END ----------------------------------------*/
/*---------------------------------------- COMMUNICATION TAB CSS START ----------------------------------------*/
.send_new {
    background: #004FC5;
    border-radius: 10px;
    padding: 8px 12px;
    font-size: 16px;
    width: fit-content;
}

/*---------------------------------------- COMMUNICATION TAB CSS END ----------------------------------------*/
.searchbar input {
    height: 40px !important;
}

.prof-inside {
    min-height: -moz-fit-content;
    min-height: 100px;
    max-height: 500px;
}

.assig-member .row {
    min-height: -moz-fit-content;
    min-height: 50px;
    max-height: 400px;
    overflow-y: scroll;
}

.scrollarea {
    margin-top: 15px !important;
    height: 400px;
    max-height: 400px;
    overflow-y: scroll;
}

.h_450 {
    height: 450px;
    overflow: visible;
}

.assig-member {
    min-height: -moz-fit-content;
    min-height: 100px;
    height: fit-content;
    overflow-y: scroll;
}

.assigned-box li {
    background: #EBEBEB;
    border-radius: 20px;
    padding: 10px 15px;
}

.custom-progress-bar {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
}

.custom-progress-bar::-webkit-progress-bar {
    background-color: #f0f0f0;
    border-radius: 10px;
}

.custom-progress-bar::-webkit-progress-value {
    background-color: #4caf50;
    border-radius: 10px;
}

.custom-progress-bar::-moz-progress-bar {
    background-color: #4caf50;
    border-radius: 10px;
}

progress::-moz-progress-bar {
    background: blue;
}

progress::-webkit-progress-value {
    background: blue;
}

progress {
    color: blue;
}

.upload-thumb .uploaded-img {
    background-color: rgb(232 235 238);
    width: fit-content;
    margin: 0 auto;
}

.upload-thumb .uploaded-img.picture {
    background-color: rgb(232 235 238);
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin: 0 auto;
}

.upload-thumb .uploaded-img img {
    width: 200px;
    height: 200px !important;
    object-fit: cover;
    border-radius: 10px;
}

.cancelimg {
    width: 36px;
    max-width: 36px;
    height: 36px;
    background: #4e4e4eab;
    border-radius: 10px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
}

.c-text {
    text-align: center;
    white-space: pre;
    width: 100%;
}

/*---------------------------------------- Opeator Accordion ----------------------------------------*/
.operator-drop .accordion-item {
    border: none;
    margin-bottom: 15px;
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 10%) !important;
    border-radius: 20px !important;
}

.operator-drop img {
    top: 5%;
    right: 14%;
    width: 18px;
    height: 25px;
    z-index: 99999;
}

.operator-drop .accordion-item {
    background-color: transparent !important;
}

.operator-drop .accordion-button {
    background: #fff;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    color: #000;
    font-size: 18px;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    box-shadow: none;
}

.operator-drop .accordion-button:hover {
    z-index: inherit;
}

.operator-drop .accordion-button.collapsed {
    background: #ffffff;
}

.operator-drop .accordion-button[aria-expanded="true"] {
    background: #ededed;
}

.operator-drop a.edt_icon {
    top: 0;
    right: 60px;
}

.operator-drop .accordion-button::after {
    transform: rotate(180deg);
}

.operator-drop .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg) !important;
}

.operator-drop .accordion-button[aria-expanded="false"] {
    background: #ffffff;
    color: #000;
}

.operator-drop .accordion-button::after {
    background-image: url('../src/assets/images/drop_down.png') !important;
    width: 17px;
    height: 14px;
    background-size: auto;
    background-position: bottom;
}

.operator-drop h4 {
    color: #000;
    font-weight: 600;
    word-break: break-word;
}

.operator-drop p {
    color: #747475;
}

.operator-drop .accordion-header svg {
    position: absolute;
    top: 14px;
    right: 18%;
    width: 15px;
    z-index: 99999;
}

.operator-drop .accordion-button[aria-expanded="false"] a {
    display: none;
}

.operator-drop .accordion-button[aria-expanded="true"] a {
    display: block;
}

/*---------------------------------------- Accordion End ----------------------------------------*/
/*---------------------------------------- Opeator Accordion ----------------------------------------*/
.director-drop .accordion-item {
    border: none;
    margin-bottom: 15px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    border-radius: 20px !important;
}

.director-drop img {
    top: 5%;
    right: 14%;
    width: 18px;
    height: 25px;
    z-index: 99999;
}

.director-drop .accordion-item {
    background-color: transparent !important;
}

.director-drop .accordion-button {
    background: #fff;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    color: #000;
    font-size: 18px;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    box-shadow: none;
}

.director-drop .accordion-button:hover {
    z-index: inherit;
}

.director-drop .accordion-button.collapsed {
    background: #ffffff;
}

.director-drop .accordion-button[aria-expanded="true"] {
    background: #ededed;
}

.director-drop a.edt_icon {
    top: 0;
    right: 60px;
}

.director-drop .accordion-button::after {
    transform: rotate(180deg);
}

.director-drop .accordion-button:not(.collapsed)::after {
    transform: rotate(0deg) !important;
}

.director-drop .accordion-button[aria-expanded="false"] {
    background: #ffffff;
}

.director-drop .accordion-button::after {
    background-image: url('../src/assets/images/drop_down.png') !important;
    width: 17px;
    height: 14px;
    background-size: auto;
    background-position: bottom;
}

.director-drop h4 {
    color: #000;
    font-weight: 600;
    word-break: break-word;
}

.director-drop p {
    color: #747475;
}

.director-drop .accordion-header svg {
    position: absolute;
    top: 14px;
    right: 12%;
    width: 15px;
    z-index: 99999;
}

.director-drop .accordion-button[aria-expanded="false"] a {
    display: none;
}

.director-drop .accordion-button[aria-expanded="true"] a {
    display: block;
}

.director-container {
    /* width: 100%;
    height: -moz-fit-content;
    max-height: 400px;
    min-height: -moz-fit-content;
    min-height: auto;
    overflow-y: scroll; */
    /* height: 400px; */
}

.director {
    margin-bottom: 10px;
    background: #fff;
    border-radius: 20px;
}

.director.active {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    max-height: 400px;
    overflow-y: scroll;
    border-radius: 20px 20px 0px 0px;
}

.director-head {
    background-color: #F1F1F1;
    padding: 15px;
    position: relative;
    cursor: pointer;
    border-radius: 20px;
    margin: 15px 0px 0px 0px !important;
}

.director-head.active img {
    display: block;
}

.director-head img {
    width: 15px;
    position: absolute;
    top: 24px;
    right: 65px;
    display: none;
}

.d_leftcont img {
    position: relative !important;
    width: -moz-fit-content;
    width: 55px;
    max-width: 55px;
    display: block;
    top: 0;
    left: 0;
}

.dropicon img {
    position: relative !important;
    width: 15px;
    display: block;
    top: 0;
    left: 0;
    margin-left: auto !important;
}

.director-head::before,
.director-head::after {
    content: '';
    position: absolute;
    top: 30%;
    transition: all .3s;
}

.director-head::before {
    right: 18px;
    background-image: url('../src/assets/images/drop_down.png');
    width: 12px;
    height: 20px;
    background-repeat: no-repeat;
    margin-top: -5px;
    display: none;
}

.director-head::after {
    right: 21px;
    width: 20px;
    height: 3px;
    margin-top: -2px;
    display: none;
}

.director-head p {
    color: #000;
    font-weight: bold;
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
}

.director-content {
    padding: 20px;
    display: none;
}

.director.active .director-content {
    padding: 0px 20px 20px 20px;
    display: block;
}

.director.active .director-head {
    background: #ffffff;
}

.director-content p {
    margin: 0px;
}

.director-head h4,
.director-head p {
    word-break: break-all !important;
}

.other-info-box h4,
.other-info-box p {
    word-break: break-all !important;
}

.director-head.active::before {
    transform: rotate(180deg);
    margin-top: -13px;
}

.director-head a {
    position: absolute;
    top: 12px;
    right: 45px;
    display: none;
}

.director.active .director-head a svg {
    width: 16px;
}

.director.active .director-head a {
    display: block;
}

.pagination {
    box-shadow: none;
}

.page-item {
    white-space: nowrap;
    cursor: pointer;
}

.page-link {
    width: 35px;
    height: 35px;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.page-link[aria-label="Previous page"],
.page-link[aria-label="Next page"] {
    width: fit-content;
    padding: 0px 15px;
}

.active>.page-link,
.page-link.active {
    background-color: #004FC5 !important;
}

.page-item a {
    color: #004FC5;
}

/*---------------------------------------- NOTES TAB CSS START ----------------------------------------*/
.notes_area {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    position: relative
}

.left_notes {
    width: 70% !important;
}

.right_notes {
    width: 30% !important;
    position: fixed;
    top: 70px !important;
    bottom: 0;
    overflow-y: scroll;
}

.right_notes form input,
.right_notes form textarea {
    background: #fff !important;
    border: none !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.063) !important;
}

.right_notes form select {
    background: #fff !important;
    border: none !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.063) !important;
}

.right_notes form input::placeholder {
    font-size: 18px;
}

.right_notes form label {
    font-size: 18px;
}

.notes-head {
    background-image: url("../src/assets/images/note-bg.png");
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    background-repeat: no-repeat;
}

.noteform-area .dp-box {
    border: none;
}

.noteform-area::before {
    content: '';
    border: 0.5px solid #77A0DF;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: none;
}

.noteform-area .vr-line {
    width: 2px;
    background: #77A0DF;
    position: absolute;
    left: 22px;
    top: 0;
    bottom: 0;
}

.l_chatbox {
    border-radius: 0px 20px 20px 20px;
    background: #fff;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px;
    max-width: 70%;
}

.r_chatbox {
    border-radius: 20px 0px 20px 20px;
    background: #DFEEFE;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px;
    max-width: 70%;
}

.r_chatbox h6 {
    color: #004FC5;
}

.notes-icon {
    display: none;
    position: fixed;
    bottom: 8px;
    right: 8px;
    animation: 2s shake infinite alternate;
}

@keyframes shake {
    0% {
        transform: skewX(-15deg);
    }

    5% {
        transform: skewX(15deg);
    }

    10% {
        transform: skewX(-15deg);
    }

    15% {
        transform: skewX(15deg);
    }

    20% {
        transform: skewX(0deg);
    }

    100% {
        transform: skewX(0deg);
    }
}

.show-message .chat-area::before {
    display: none;
}

.close_message {
    position: absolute;
    top: 20px;
    right: 20px;
}

.close_message i {
    font-size: 24px;
}

.right_notes .close_message {
    display: none;
}

.show-message .chat-area {
    padding: 25px 0px 25px 60px !important;
}

.attachment span {
    width: max-content;
}

.notes-btn-group {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 3px 2px 4px 2px;
    height: 40px;
    width: -moz-fit-content;
    width: fit-content;
}

.notes-btn-group button {
    border-radius: 8px;
    padding: 10px 14px;
    border: 0px;
    font-size: 14px;
    color: #9EA0A1;
    font-weight: 300;
    background: none;
    height: 35px;
    display: flex;
    align-items: center;
}

.notes-btn-group button:hover {
    background: #000000;
    color: #f1f1f1;
}

.notes-btn-group button.nav-link.active {
    background: #000000 !important;
    color: #f1f1f1;
}

button.nav-link.active {
    background: #000000 !important;
    color: #f1f1f1;
    border-radius: 8px;
    height: 34px;
}

.notes-btn-group hr {
    height: 20px;
    width: 1px;
    background: #B1B3B5;
    margin: 5px 0px;
    opacity: 1;
    border: none;
}

.note-box h4 {
    font-weight: 600;
}

.note-tags span {
    background: #EDEDEE;
    border-radius: 50px;
    padding: 8px 12px;
    color: #000;
}

.note-client img {
    width: 26px;
    height: 26px;
}

.l_client {
    border-radius: 50px;
    border: 1px solid #DCDCDC;
    padding-right: 10px;
}

.r_client span {
    border-radius: 8px;
    width: 30px;
    height: 30px;
    background: #EDEDEE;
}

.r_client span svg {
    fill: #202529;
}

.r_client span:hover {
    background: #202529;
    transition: 0.4s;
}

.r_client span:hover svg {
    fill: #fff;
}

.close_message_notes {
    position: absolute;
    top: 10px;
    right: left;
    left: 20px;
    display: none;
}



/*---------------------------------------- NOTES TAB CSS START ----------------------------------------*/
.files_area {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    position: relative
}

.left_files {
    width: 70% !important;
}

.right_files {
    width: 30% !important;
    position: fixed;
    top: 70px !important;
    bottom: 0;
    overflow-y: scroll;
}

.right_files form input,
.right_files form textarea {
    background: #fff !important;
    border: none !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.063) !important;
}

.right_files form select {
    background: #fff !important;
    border: none !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.063) !important;
}

.right_files form input::placeholder {
    font-size: 18px;
}

.right_files form label {
    font-size: 18px;
}

.files-head {
    background-image: url("../src/assets/images/note-bg.png");
    width: 100%;
    height: auto;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    background-repeat: no-repeat;
}

.filesform-area .dp-box {
    border: none;
}

.filesform-area::before {
    content: '';
    border: 0.5px solid #77A0DF;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: none;
}

.filesform-area .vr-line {
    width: 2px;
    background: #77A0DF;
    position: absolute;
    left: 32px;
    top: 0;
    bottom: 0;
}

.l_chatbox {
    border-radius: 0px 20px 20px 20px;
    background: #fff;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px;
    max-width: 70%;
}

.r_chatbox {
    border-radius: 20px 0px 20px 20px;
    background: #DFEEFE;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px;
    max-width: 70%;
}

.r_chatbox h6 {
    color: #004FC5;
}

.files-icon {
    display: none;
    position: fixed;
    bottom: 8px;
    right: 8px;
    animation: 2s shake infinite alternate;
}

@keyframes shake {
    0% {
        transform: skewX(-15deg);
    }

    5% {
        transform: skewX(15deg);
    }

    10% {
        transform: skewX(-15deg);
    }

    15% {
        transform: skewX(15deg);
    }

    20% {
        transform: skewX(0deg);
    }

    100% {
        transform: skewX(0deg);
    }
}

.show-message .chat-area::before {
    display: none;
}

.close_message {
    position: absolute;
    top: 20px;
    right: 20px;
}

.close_message i {
    font-size: 24px;
}

.right_notes .close_message {
    display: none;
}

.show-message .chat-area {
    padding: 25px 0px 25px 60px !important;
}

.attachment span {
    width: max-content;
}

.files-btn-group {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 3px 2px 4px 2px;
    height: 40px;
    width: -moz-fit-content;
    width: fit-content;
}

.files-btn-group button {
    border-radius: 8px;
    padding: 10px 14px;
    border: 0px;
    font-size: 16px;
    color: #9EA0A1;
    font-weight: 500;
    background: none;
    height: 35px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.files-btn-group button svg {
    fill: #000000;
}

.files-btn-group button svg.list {
    stroke: #000000;
}

.files-btn-group button:hover {
    background: #000000;
    color: #f1f1f1;
}

.files-btn-group button:hover svg {
    fill: #ffffff;
}

.files-btn-group button:hover svg.list {
    stroke: #ffffff;
}

.files-btn-group button.grid_list.active {
    background: #000000 !important;
    color: #f1f1f1;
}

.files-btn-group button.grid_list.active {
    background: #000000 !important;
    color: #f1f1f1;
    border-radius: 8px;
    height: 34px;
}

.files-btn-group button.active svg {
    fill: #ffffff;
}

.files-btn-group button.active svg.list {
    stroke: #ffffff;
}


.files-btn-group hr {
    height: 20px;
    width: 1px;
    background: #B1B3B5;
    margin: 5px 0px;
    opacity: 1;
    border: none;
}

.files-box h4 {
    font-weight: 600;
}

.files-tags span {
    background: #EDEDEE;
    border-radius: 50px;
    padding: 8px 12px;
    color: #000;
}

.files-client img {
    width: 26px;
    height: 26px;
}

.l_client {
    border-radius: 50px;
    border: 1px solid #DCDCDC;
    padding-right: 10px;
}

.r_client span {
    border-radius: 8px;
    width: 30px;
    height: 30px;
    background: #EDEDEE;
}

.r_client span svg {
    fill: #202529;
}

.r_client span:hover {
    background: #202529;
    transition: 0.4s;
}

.r_client span:hover svg {
    fill: #fff;
}

.close_message_files {
    position: absolute;
    top: 10px;
    right: left;
    left: 20px;
    display: none;
}

.files_upload.main-wrapper {
    border: 1px dashed #212529;
    padding: 35px 20px;
    border-radius: 10px;
    margin: 20px 0px !important;
}

.right_files input[type="checkbox"] {
    width: 18px !important;
    height: 18px !important;
    display: flex;
    display: -webkit-flex;
}

.right_files .comp-text {
    width: 100%;
}

.create_folder a {
    height: 136px;
    display: block;
    width: fit-content;
}

.create_folder p {
    position: absolute;
    right: 0;
    bottom: 0px;
    left: 0;
    text-align: center;
    color: #004FC5;
}

.l-head .link_btn {
    width: 38px;
    height: 30px;
    background-color: #EDEDEE;
    border-radius: 8px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.l-head .dot_btn {
    width: 30px;
    height: 30px;
    background-color: #EDEDEE;
    border-radius: 8px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.l-head .dot_btn .dot_icon span {
    width: 3px;
    height: 3px;
    border-radius: 50px;
    background-color: #000000;
    display: block;
    margin: 3px 0px !important;
}

/* FILES TAB END */

/*---------------------------------------- TIMELINE ----------------------------------------*/
.timeline {
    margin: auto;
    padding: 0 1.5em;
    width: 100%;
    max-width: 36em;
}

.timeline__arrow {
    background-color: transparent;
    border-radius: 0.25em;
    cursor: pointer;
    flex-shrink: 0;
    margin-inline-end: 0.25em;
    outline: transparent;
    width: 2em;
    height: 2em;
    transition: background-color calc(var(--trans-dur) / 2) linear, color var(--trans-dur);
    -webkit-appearance: none;
    appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.timeline__arrow:focus-visible,
.timeline__arrow:hover {
    background-color: hsl(var(--hue), 10%, 50%, 0.4);
}

.timeline__arrow-icon {
    display: block;
    pointer-events: none;
    transform: rotate(-90deg);
    transition: transform var(--trans-dur) var(--trans-timing);
    width: 100%;
    height: auto;
}

.timeline__date {
    font-size: 0.833em;
    line-height: 2.4;
}

.timeline__dot {
    background-color: #eef1f4;
    border-radius: 50%;
    display: flex;
    display: -webkit-flex;
    margin: 0.625em 0;
    margin-inline-end: 1em;
    position: relative;
    width: 18px;
    height: 18px;
    outline: 2px solid #000;
    align-items: center;
    justify-content: center;
}

.dot {
    background: #000;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    margin-left: 1px;
}

.timeline__item {
    position: relative;
    padding-bottom: 2.25em;
}

.timeline__item:not(:last-child):before {
    border: 1px dashed #DCDCDC;
    content: "";
    display: block;
    position: absolute;
    top: 40px;
    left: 9px;
    width: 0.125em;
    height: 100%;
    transform: translateX(-50%);
}

[dir="rtl"] .timeline__arrow-icon {
    transform: rotate(90deg);
}

[dir="rtl"] .timeline__item:not(:last-child):before {
    right: 2.625em;
    left: auto;
    transform: translateX(50%);
}

.timeline__item-header {
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.timeline__item-body {
    border-radius: 0.375em;
    overflow: hidden;
    margin-top: 0.5em;
    margin-inline-start: 4em;
    height: 0;
}

.timeline__item-body-content {
    background-color: hsl(var(--hue), 10%, 50%, 0.2);
    opacity: 0;
    padding: 0.5em 0.75em;
    visibility: hidden;
    transition: opacity var(--trans-dur) var(--trans-timing), visibility var(--trans-dur) steps(1, end);
}

.timeline__meta {
    width: 100%;
}

.timeline__title {
    font-size: 1.5em;
    line-height: 1.333;
}

.timeline__item-body--expanded {
    height: auto;
}

.timeline__item-body--expanded .timeline__item-body-content {
    opacity: 1;
    visibility: visible;
    transition-delay: var(--trans-dur), 0s;
}

.timeline__arrow[aria-expanded="true"] .timeline__arrow-icon {
    transform: rotate(0);
}

@media (prefers-color-scheme: dark) {
    :root {
        --bg: hsl(var(--hue), 10%, 10%);
        --fg: hsl(var(--hue), 10%, 90%);
        --primary: hsl(var(--hue), 90%, 70%);
    }
}

label {
    width: 100%;
}

.select2-container .select2-selection--multiple {
    min-height: 50px !important;
}

.select2-container--default .select2-selection--multiple {
    border-radius: 10px !important;
    background-color: #fff !important;
    padding: 8px 15px 8px 15px;
    padding-top: 8px;
    border: 1px solid #EDEEEE !important;
    height: fit-content;
    width: 100%;
    margin-top: 8px;
}

.select2-container {
    width: 100% !important;
    height: fit-content;
}

.select2-results__option {
    font-family: 'Poppins', sans-serif !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li.select2-selection__choice {
    list-style: none;
    width: auto;
    background: #D9E5F7;
    border: none;
    padding: 10px;
    border-radius: 50px;
    color: #004FC5;
    font-size: 18px;
}

.bootstrap-tagsinput {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    border-radius: 4px;
    min-height: 50px !important;
    max-height: fit-content !important;
    margin-top: .5rem !important;
    align-items: center;
    border-radius: 10px !important;
    background: #fff !important;
    padding-left: 15px;
    width: 100%;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.063) !important;
}

.bootstrap-tagsinput input {
    background: none;
}

.tag-item {
    background: #D9E5F7;
    color: #004FC5;
    border-radius: 50px;
    padding: 3px 14px;
    display: flex;
    align-items: center;
    margin: 5px !important;
    height: 30px;
    font-size: 14px;
}

.tag-text {
    margin-right: 15px;
}

.tag-close {
    cursor: pointer;
    padding-left: 10px;
}

input[name="input"] {
    border: none;
    outline: none;
    flex-grow: 1;
    min-width: 100px;
}

.left-task-modal {
    padding: 15px 15px 20px 35px;
}

.left-task-modal .upload-main-wrapper {
    width: 100%;
    border: 2px dashed #000;
    padding: 10px;
    border-radius: 8px;
}

.left-task-modal .upload-wrapper {
    justify-content: flex-start;
    margin: 0;
}

.btn-blue {
    border: 1px solid #004FC5 !important;
}

.btn-cancel {
    background: #fff;
    color: #000;
    border-radius: 10px;
    padding: 8px 30px;
    font-size: 16px;
    border: 1px solid #D9D9D9;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
}

.btn-add {
    background: #004FC5;
    ;
    color: #fff;
    border-radius: 10px;
    padding: 8px 30px;
    font-size: 16px;
    border: 1px solid #004FC5;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
}

button.view-full {
    background: #F0F3F9;
    color: #77A0DF;
    border-radius: 10px;
    padding: 12px 20px;
    border: 1px solid #77A0DF;
    font-size: 16px;
    height: 40px;
    border: 1px solid #004FC5;
    display: flex;
    align-items: center;
}

.image-preview-area {
    width: fit-content;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    flex-wrap: wrap;
}

.image-preview-area .image-preview img {
    margin-top: 15px !important;
    border-radius: 10px;
    object-fit: cover;
}

.image-preview-area .image-preview button svg {
    position: absolute;
    top: 20px;
    right: 16px;
}

.right-task-modal {
    background-color: #F0F3F9;
    border-radius: 8px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 32%;
    top: 0;
}

.right-task-modal h5 {
    cursor: pointer;
}

.right-task-modal label {
    font-size: 14px;
    color: #747475;
    padding: 3px 0px;
    cursor: pointer;
}

.right-task-modal {
    overflow-y: scroll;
}

.right-task-modal h6 {
    color: #004FC5;
    font-weight: 500 !important;
    font-size: 16px;
}

.right-task-modal h4 {
    color: #747475;
    font-weight: 500 !important;
    font-size: 14px;
}

.right-task-modal select {
    background-color: #f0f3f9 !important;
    border: 0px;
    color: #004FC5;
    font-weight: 500 !important;
    background-image: none !important;
    padding: 0px 0px;
    border: none !important;
    height: 30px !important;
    font-size: 16px;
}

.right-task-modal .date select {
    border-radius: 10px !important;
    background: #EDEEEE !important;
    padding-left: 20px;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: 100%;
}

.right-task-modal input[type="date"] {
    font-size: 14px;
}

.right-task-modal select option {
    padding: 10px;
}

.right-task-edit {
    background-color: #F0F3F9;
    width: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    max-width: 100%;
}

.right-task-edit h5 {
    cursor: pointer;
}

.right-task-edit label {
    font-size: 14px;
    color: #747475;
    padding: 3px 0px !important;
}

.right-task-edit {
    overflow-y: scroll;
}

.right-task-edit h6 {
    color: #004FC5;
    font-weight: 500 !important;
    font-size: 16px;
    cursor: pointer;
}

.right-task-edit h4 {
    color: #747475;
    font-weight: 700 !important;
    font-size: 14px;
    padding: 0px !important;
}

.right-task-edit input {
    background-color: #ffffff !important;
}

.right-task-edit select {
    background-color: #f0f3f9 !important;
    border: 0px;
    color: #004FC5;
    font-weight: 500 !important;
    background-image: none !important;
    padding: 0px 0px;
    border: none !important;
    height: 30px !important;
    font-size: 16px;
    text-align: center !important;
    padding: 0px !important;
}

.right-task-edit select.bg-white {
    background-color: #ffffff !important;
}

.right-task-edit .date select {
    border-radius: 10px !important;
    background: #ffffff !important;
    padding-left: 20px;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: 100%;
    text-align: center !important;
    padding: 0px !important;
}

.right-task-edit input[type="date"] {
    font-size: 14px;
}

.right-task-edit select option {
    padding: 10px;
}

.modal {
    font-family: 'Poppins', sans-serif !important;
}

/* ---------------------------------------- 08-06-2024 ---------------------------------------- */
.det-input {
    background: none !important;
    border: none !important;
    height: 56px !important;
    font-weight: 600;
}

.assign-open li label {
    font-size: 18px;
}

.spinner-border {
    border: none !important;
    width: 0px !important;
}

.loaderDiv {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 99999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000000be;
}

.loader {
    color: #fff;
    font-size: 10px;
    width: 1em !important;
    height: 1em !important;
    border-radius: 50% !important;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear !important;
    transform: translateZ(0);
}

@keyframes mulShdSpin {

    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }

    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    25% {
        box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }

    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }

    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }

    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }

    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #858796;
    content: ">" !important;
}

.breadcrumb-item.active {
    color: #000 !important;
    font-weight: 600 !important;
}

.breadcrumb-item.active a {
    color: inherit !important;
    text-decoration: none !important;
}

/*---------------------------------------- INVOICE START ----------------------------------------*/
a {
    color: #000;
}

table tr td a {
    color: #000;
}

.invoice-head-main {
    background: #FFFFFF;
    padding: 20px;
    border-radius: 10px;
    width: 550px;
    min-width: -moz-fit-content;
    min-width: fit-content;
    box-shadow: 0px 0px 20px #004FC514;
}

.invoice-head-main h4 {
    font-size: 36px;
    color: #000;
    font-weight: bold;
}

.invoice-head-main p {
    font-size: 20px;
    color: #065AC4;
    font-weight: 500;
}

.invoice-head-main span {
    display: block;
    width: 1px;
    height: 70px;
    background-color: rgb(6 92 196 / 10%);
    margin: 0px 30px;
}

.invoice-area ul {
    border: none;
}

.invoice-area li.nav-item button {
    border-radius: 50px;
    border: 1px solid #DCDCDC;
    color: #747475;
    font-size: 18px;
    padding: 13px 25px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    height: 40px;
    font-weight: 400;
}

.invoice-area li.nav-item button.active {
    background-color: #000;
    color: #fff;
}

.invoice-btn-group {
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 3px;
    width: fit-content;
    height: 40px;
}

.invoice-btn-group button {
    border-radius: 8px;
    padding: 10px 14px;
    border: 0px;
    font-size: 14px;
    color: #9EA0A1;
    font-weight: 300;
    background: none;
}

.invoice-btn-group button:hover {
    background: #000;
    color: #fff !important;
}

.invoice-area button:hover {
    background: #000;
    color: #fff !important;
}

.invoice-btn-group hr {
    height: 20px;
    width: 1px;
    background: #B1B3B5;
    margin: 5px 0px;
    opacity: 1;
    border: none;
}

.pdf-preview-area {
    width: auto;
    max-width: auto;
}

.invoice-table thead th:nth-child(1) {
    padding: 12px 0px;
}

.invoice-table thead th {
    font-size: 16px;
    color: #747475;
    padding: 12px;
}

.invoice-table tbody tr {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #004FC514;
}

.invoice-table tbody tr td {
    padding: 15px;
    color: #000;
    font-size: 16px;
}

.active-badge {
    font-size: 14px;
    color: #1C8B15;
    background: #D1E8D0;
    border-radius: 8px;
    padding: 4px 12px;
    width: fit-content;
    display: block;
    white-space: nowrap;
    text-align: center;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.invoice-table tbody tr td .active {
    font-size: 14px;
    color: #1C8B15;
    background: #D1E8D0;
    border-radius: 8px;
    padding: 6px 12px;
    width: 80px;
    display: block;
    text-align: center;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.invoice-table tbody tr td .inactive {
    font-size: 14px;
    color: #F93333;
    background: #FED6D6;
    border-radius: 8px;
    padding: 6px 12px;
    width: 80px;
    display: block;
    text-align: center;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.invoice-table tbody tr td .disabls {
    font-size: 14px;
    color: #202529;
    background: #EBEBEB;
    border-radius: 8px;
    padding: 6px 12px;
    width: fit-content;
    display: block;
    text-align: center;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.invoice-table tbody tr td .publish {
    font-size: 14px;
    color: #024FC5;
    background: #D5E2F6;
    border-radius: 8px;
    padding: 6px 12px;
    width: fit-content;
    display: block;
    text-align: center;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.invoice-table tbody tr td .status_badge {
    font-size: 14px;
    border-radius: 8px;
    padding: 6px 12px;
    width: fit-content;
    display: block;
    text-align: center;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.publish.paid {
    background-color: lightgreen;
    color: green;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.publish.draft {
    background-color: lightgray;
    color: black;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.publish.unpaid {
    background-color: lightcoral;
    color: red;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.publish.partially-paid {
    background-color: lightyellow;
    color: yellow;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.invoice-table tbody tr td .partial {
    font-size: 14px;
    color: #FFAA27;
    background: #FFEECC;
    border-radius: 8px;
    padding: 6px 12px;
    width: 80px;
    display: block;
    text-align: center;
    height: 28px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.invoice-table td a.dropdown-toggle::after {
    border: none;
}

/*---------------------------------------- INVOICE START ----------------------------------------*/
/*---------------------------------------- ADD INVOICE START ----------------------------------------*/
.invoice_row {
    display: flex;
    display: -webkit-flex;
    align-items: start;
    gap: 20px;
}

.l_invoice {
    width: 100%;
}

.r_invoice {
    display: none;
    width: 45%;
    box-shadow: 0px 0px 20px #004FC514;
    border-radius: 20px;
}

.l_invoice.showinvoice {
    width: 55%;
}

.r_invoice.showinvoice {
    display: block;
}

span.paid {
    width: fit-content;
    height: 28px;
    background: #D1E8D0;
    color: #1C8B15;
    border-radius: 8px;
    font-size: 14px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    padding: 5px 18px;
}

.invoice-pdfbox {
    background: #D5E2F6;
}



.inv-title {
    font-size: 20px;
    font-weight: 600;
}

.add-invoice-area label {
    font-size: 18px;
    color: #747475;
}

.radio-inv label {
    font-size: 14px;
}

input[type='radio']:checked {
    accent-color: #000;
}

.radio-inv input[type="radio"] {
    width: 18px;
    height: 18px;
    border-color: #00AD51;
    background-color: #00AD51;
    color: #fff;
}

[type="radio" i] {
    background-color: red;
    border: 2px solid red;
}

.prev-btns button {
    height: 40px;
}

.btn-transparent {
    border: none;
    background: transparent;
    color: #000;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
    height: 40px;
    padding: 7px 20px !important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    width: fit-content;
}

.gray-btn {
    border: none;
    background: #EDEDEE;
    color: #000;
    font-size: 16px;
    border-radius: 10px;
    height: 40px;
    padding: 7px 20px !important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    width: fit-content;
}

.main-table-area {
    padding: 0px 12px;
}

.custom-drop {
    border-radius: 10px !important;
    background: #EDEEEE !important;
    padding: 8px 8px 8px 20px;
    border: 1px solid #EDEEEE !important;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-drop h5 {
    font-size: 18px;
}

.custom-drop-open {
    display: none;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
}

.custom-drop-open.show-custom {
    display: block;
    position: absolute;
    right: 0;
    left: 0;
}

/*---------------------------------------- FILTER BUTTON START ----------------------------------------*/
.custom-filter {
    border-radius: 10px !important;
    background: #000 !important;
    padding: 10px 12px;
    border: 1px solid #EDEEEE !important;
    height: 50px;
    width: 150px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-filter h5 {
    font-size: 14px;
    color: #fff;
}

.custom-filter-open ul li {
    border-bottom: 1px solid #DCDCDC;
    padding: 10px 0px;
}

.custom-filter-open ul li:last-child {
    border: none;
}

.custom-filter-open {
    display: block;
    position: absolute;
    right: 0;
    left: 0;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
    z-index: 1;
}

.custom-filter-open ul li {
    cursor: pointer;
    padding: 8px 10px;
}

.custom-filter-open ul li:hover {
    background: #BED4F0;
}

.rotate {
    transition: transform 0.3s;
}

.rotate.open {
    transform: rotate(180deg);
}

/*---------------------------------------- FILTER BUTTON END ----------------------------------------*/
.pop-head h4 {
    font-weight: bold;
    font-size: 20px;
}

.modal-content {
    border: none !important;
    border-radius: 20px !important;
    padding: 0px !important;
}

.per-inp {
    position: relative;
}

.invoicing input {
    padding-left: 10px;
}

.discount .per {
    border-radius: 0px 10px 10px 0px !important;
    background: #EDEEEE !important;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: fit-content;
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
}

.discount .per p:hover {
    height: 40px;
    background: #000;
    border-radius: 5px;
    color: #fff;
    position: relative;
    top: 0;
    text-align: center;
    margin: auto;
    display: felx;
    display: flex;
    align-items: center;
}

.discount input {
    border-radius: 10px !important;
    position: relative;
}

.item-inp {
    max-width: 30%;
}

.desc-inp {
    max-width: 35%;
}

.qty-inp,
.price-inp,
.amount-inp {
    max-width: 10%;
}

.disc-inp {
    max-width: 15%;
}

.total h6 {
    font-weight: 600;
}

.client-info {
    border: 1px solid #DCDCDC;
    padding: 15px;
    border-radius: 20px;
    margin-top: 15px;
}

.client-info h3 {
    line-height: normal;
}

.light-blue-bg {
    background: #F1F6FC;
    border-radius: 10px;
}

/*---------------------------------------- ADD INVOICE END ----------------------------------------*/
.invoice-btn-group button.active {
    background-color: #0d0d0e;
    color: white;
}

.dropdown-custom select {
    height: 50px !important;
    background-image: none !important;
    background-color: #F1F1F1;
}

.dropdown-custom select:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
}

.per p {
    cursor: pointer;
    margin: 0;
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 3px;
}

.per p.active {
    background-color: #000000;
    color: #fff;
    border-color: #000000;
}

/*---------------------------------------- INVOICE PREVIEW START ----------------------------------------*/
.invoice-table tbody tr.active_tr {
    background-color: #bfcce0 !important;
}

.pdf-preview-area {
    max-width: auto;
    margin: 0 auto;
    overflow-x: auto;
    padding-top: 20px;
}

.pdf-preview-area table {
    width: 100%;
    border-collapse: collapse;
    padding: 15px;
    white-space: normal;
}

.pdf-preview-area table tr {
    margin: 0px 15px;
}

.pdf-preview-area table td {
    width: 50%;
    vertical-align: top;
    padding: 0px 20px;
    border-radius: 0;
}

.pdf-preview-area table tbody tr {
    box-shadow: none;
}

.pdf-preview-area table tr td img {
    width: 170px;
    object-fit: contain;
}

.pdf-preview-area table tr td h4 {
    font-size: 20px;
    font-weight: bold;
    color: #004FC5;
}

.pdf-preview-area table tr td h2 {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}

.pdf-preview-area table tr td h3 {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    line-height: normal;
}

.pdf-preview-area table tr td h6 {
    font-size: 12px;
    font-weight: 400;
    color: #004FC5;
}

.pdf-preview-area table tr td p {
    font-size: 12px;
    font-weight: 400;
    color: #000000;
    line-height: normal;
}

.pdf-preview-area table tr td h5 {
    font-size: 12px;
    font-weight: 600;
    color: #000000;
}

.pdf-preview-area table thead {
    background: #D5E2F6;
}

.pdf-preview-area table thead tr {
    color: #024FC5;
}

.pdf-preview-area table thead tr th {
    color: #024FC5;
    font-size: 12px;
    padding: 10px 20px;
    vertical-align: middle;
}

.pdf-preview-area table.item-table {
    margin: 0px auto 0px auto !important;
    width: auto;
}

.pdf-preview-area table.item-table tr td {
    font-size: 12px;
    vertical-align: middle;
}

.pdf-preview-area table.item-table tr td hr {
    margin: 0px;
}

.pdf-preview-area .footer {
    margin-top: 100px;
}

/*---------------------------------------- 23-06-2024 ----------------------------------------*/
.client-tabs .nav-tabs .nav-link {
    color: #747475;
    border-radius: 50px;
    border: 1px solid #DCDCDC;
    height: 40px;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 18px;
}

.client-tabs .nav-tabs .nav-link:hover {
    background: #000;
    color: #fff;
    transition: 0.4s;
}

.client-tabs .nav-tabs .nav-link.active {
    background: #000000;
    color: #ffffff;
}

/*---------------------------------------- 23-06-2024 ----------------------------------------*/
/*---------------------------------------- TASK DETAILS CSS START ----------------------------------------*/
.add-task {
    position: relative;
    height: 100%;
}

.task-pics {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}


.task-pics img {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
}

.task-pics .pdf-box {
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background: #F9C3C5;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

hr.task-hr {
    color: #004FC5;
    opacity: 1;
}

.comments-area .l_comment {
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

.l_comment img {
    max-width: 45px;
    max-height: 45px;
    object-fit: cover;
    border-radius: 100px;
    width: 45px;
}

/* .row.comment-boxes {
    min-height: fit-content;
    max-height: fit-content;
    overflow-y: scroll;
} */

.row.send-comment {
    background: #F0F3F9;
    position: fixed;
    bottom: 0;
    left: 118px;
    right: 0;
    padding: 25px;
}

.send-comment input {
    background: #fff;
    border-radius: 50px;
    padding: 15px 25px;
    border: none;
    width: 100%;
    height: 50px;
}

.send-comment input::placeholder {
    font-size: 14px;
}

.image-upload>input {
    display: none;
}

.image-upload {
    position: absolute;
    right: 100px;
}

/*---------------------------------------- TASK DETAILS CSS END ----------------------------------------*/
.is-invalid {
    border-color: #dc3545;
    background-color: #f8d7da;
}

.text-danger {
    color: #dc3545;
}

/*---------------------------------------- PAYMENT CSS START ----------------------------------------*/
.payment {
    display: flex;
    width: 100%;
    position: relative;
}

.payment .left-payment {
    width: 50%;
    padding: 100px 150px;
}

.payment .right-payment {
    width: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: url('../src/assets/images/right-pay-bg.jpg');
    background-repeat: round;
}

.left-payment .dollar-text {
    top: 12px;
    right: 14px;
}

.payment-btn button {
    background: #004FC5;
    border-radius: 20px;
    height: 70px;
    display: flex;
    display: -webkit-flex;
    border: none;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    padding: 15px;
}

/*---------------------------------------- PAYMENT CSS END ----------------------------------------*/
.right-task-modal .select-box {
    color: #004FC5;
    cursor: pointer;
    padding: 3px;
}

.right-task-modal .select-box.active {
    padding: 10px;
    background: #D5E2F6;
    border-radius: 5px;
}

.options-container {
    background: white;
    position: absolute;
    z-index: 100;
    width: 90% !important;
    max-width: 100%;
    border-radius: 0px 0px 10px 10px;
    margin-top: 30px !important;
    margin-right: 35px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px;
}

.options-container input {
    background-color: #D5E2F6 !important;
    border: none;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    height: 50px !important;
}

.options-container ul {
    max-height: 250px;
    overflow-y: scroll;
    margin-bottom: 10px !important;
}

.option {
    padding: 4px 15px;
    font-weight: 400;
    cursor: pointer;
    font-size: 14px;
}

.option:hover {
    background-color: #d5e2f6;
}

.select-box {
    font-size: 16px;
    font-weight: 600 !important;
    color: #004FC5;
    padding: 0px !important;
    cursor: pointer;
}

.srchinput i {
    bottom: 3px !important;
    cursor: pointer;
}

.addplus {
    background: #004FC5;
    color: #fff;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    line-height: 0px;
}

.search-field {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    margin: 0px 0px 5px 0px;
    border-radius: 10px;
    background: #edeeee;
    height: 40px;
}

.options-container input:focus {
    border: 2px solid #d5e2f6;
}

.border-input {
    border-bottom: 1px solid #ccc;
}

.edit-task-form .upload-main-wrapper {
    width: 100%;
    border: 2px dashed #000;
    padding: 10px;
    border-radius: 8px;
}

.edit-pics .pic_box img {
    border-radius: 10px;
}

.edit-pics .cross-pic {
    top: 18px;
    right: 14px;
}

.edit-pics .cross-pic svg {
    width: 30px;
}

.edit-pics .pic_box.pdf-box {
    width: 130px;
    height: 130px;
    background: #F9C3C5;
    border-radius: 10px;
}

.error {
    font-size: 13px;
    font-weight: 400;
    color: red;
    padding: 0px !important;
}

.drop_box {
    position: relative;
    width: 200px;
}

.drop_box .dropdown {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;
    background-color: white;
}

.drop_box input.dropdown::placeholder,
.drop_box .dropdown__selected,
.drop_box .react-datepicker-wrapper input::placeholder {
    font-size: 14px !important;
}

.drop_box .dropdown__selected {
    padding: 10px;
    width: 100%;
    display: flex;
    display: -webkit-flex;
    height: 32px;
    align-items: center;
}

.drop_box .dropdown__options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-top: none;
    display: none;
    z-index: 1000;
}

.drop_box .dropdown__options--open {
    display: block;
    margin-top: 1px !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px;
    border: 0px;
    padding: 10px 0px;
}

.drop_box .dropdown__option {
    padding: 3px 13px;
    cursor: pointer;
    font-size: 14px;
}

.drop_box .dropdown__option:hover {
    background-color: #f1f1f1;
}

.drop_box .dropdown {
    background: none;
    display: flex;
    width: 200px;
    height: 32px;
}

.drop_box.datepick {
    width: 250px;
}

.drop_box .react-datepicker-wrapper input {
    padding: 3px 10px;
    width: 250px;
}

.drop_box select,
.drop_box input {
    font-size: 14px !important;
    font-weight: 400 !important;
}

.drop_box .react-datepicker-wrapper input::placeholder {
    font-size: 16px !important;
    font-weight: 400 !important;
    color: #747475 !important;
}

.comments-area .sendarea {
    width: fit-content;
    position: absolute;
    right: 24px;
    bottom: 20px;
}

.drop_box .dropdown__options.dropdown__options--open input {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    margin: 0px auto 7px auto;
    background-color: #F1F2F3;
    border: none;
    padding: 4px 10px;
    width: 91%;
    border-radius: 6px;
    height: 32px;
}

.drop_box .dropdown__options.dropdown__options--open input::placeholder {
    font-size: 12px !important;
    color: #000 !important;
}

.create-blue {
    border: none;
    border: 1px solid #004FC5;
    color: #004FC5;
    font-size: 16px;
    border-radius: 10px;
    height: 40px;
    padding: 7px 10px !important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.input-trans input {
    background: none !important;
    border: none !important;
    font-weight: 600;
    padding: 0px;
    font-size: 30px;
}

.client-drops label {
    color: #747475 !important;
    font-weight: 400;
}

.text-grays {
    color: #747475 !important;
    font-weight: 400;
}

.customdrop {
    margin-top: -16px;
}

.customdrop input {
    background: #D5E2F6 !important;
}

.customdrop ul {
    height: fit-content;
    max-height: 250px;
    overflow-y: scroll;
}

span.normal {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #00733B;
}

span.critical {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #004FC5;
}

span.major {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #E5252A;
}

span.minor {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #FFAA27;
}

.disabled-div {
    pointer-events: none;
    opacity: 0.5;
}

/*---------------------------------------- OUTER TASK CSS ----------------------------------------*/
.ttl-name {
    background-color: #E5EFFF;
    width: 55px;
    height: 55px;
    border-radius: 100%;
}

.ttl-name p {
    color: #77A0DF;
    font-size: 22px;
    font-weight: 600;
}

/*---------------------------------------- OUTER TASK CSS ----------------------------------------*/
/*---------------------------------------- SETTING CSS START ----------------------------------------*/
.accord-head {
    padding: 10px 15px;
}

button:focus:not(:focus-visible) {
    outline: transparent;
}

.setting-drop .accordion-item {
    border-color: transparent;
}

.setting-drop .accordion-button :focus {
    box-shadow: none;
}

.setting-drop .accordion-button {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    gap: 8px;
    font-weight: 500;
    padding: 10px 24px;
    color: #747475;
}

.setting-drop .accordion-button::after {
    background-image: url("data:image/png;base64, iVBORw0KGgoAAAANSUhEUgAAAAsAAAAQCAYAAADAvYV+AAAABHNCSVQICAgIfAhkiAAAASFJREFUKFOFkuFtwjAQhc/E/C4bQCcoG5ROUJigdII6kiNlhKixhDconaDdALoB3SAjwG8M6TtyqUBx6Un5Eft753fPVtbaJZF6JKpnzrk1XSllbVZhf8iMUvRcliXE8VJ5no9CCJ9A7xpEeede0xiueNEYM9C6DwHd839d0/Jw2Kfe++256AS3Jf6f5H8Twv7hXHABMwSBgZWFnFD1ejTDHJuTwZi3LMvmsOKxd4MPVpqkojA3gGAMwVoEpHVy+x+8Ajxg8Z+w2HgTizvkP2Hfnc4APY5/acD6W2s9LYqiuhiQs06S/gJd5tLxC9FNO9HJpbC/sXR8x/St6DcwxVMfj/SBjiMBU4AcW6f4IfGVcp48iLn6kADzm5i0E8c6tms/dnl0Ic4GH4wAAAAASUVORK5CYII=") !important;
    background-position: bottom;
    background-size: contain;
    width: 10px;
}

.accordion-button:not(.collapsed)::after {
    transform: rotate(0deg) !important;
    background-image: url("../src/assets/images/setdrop.png") !important;
    background-position: bottom;
    width: 16px;
    background-position: center;
}

.setting-drop .accordion-button[aria-expanded="false"] {
    padding: 10px 24px;
}

.setting-drop .accordion-button[aria-expanded="true"] {
    background: #D5E2F6;
    color: #004FC5;
}

.setting-drop .accordion-button[aria-expanded="true"]>svg {
    fill: #004FC5;
}

.deticon {
    width: 30px;
    height: 30px;
    background: #EDEDEE;
    border-radius: 8px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.editicon {
    width: 30px;
    height: 30px;
    background: #EDEDEE;
    border-radius: 8px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

.accord-table thead th {
    padding: 8px 15px;
    font-size: 16px;
    white-space: nowrap;
}

.accord-table tr td:first-child {
    border-left: 1px solid #DCDCDC;
}

.accord-table tr td:last-child {
    border-right: 1px solid #DCDCDC;
}

.accord-table tr td {
    border-top: 1px solid #DCDCDC;
    border-bottom: 1px solid #DCDCDC;
    font-size: 16px;
    white-space: nowrap;
}

.accord-table .form-btns {
    margin-top: 40px !important;
}

.svc-toolbox--compact {
    width: fit-content !important;
}

.active-set {
    font-weight: 700;
    color: #004FC5;
}

.sd-title.sd-container-modern__title {
    padding: 0px;
}

h3.sd-title .sv-string-viewer {
    color: #004FC5;
}

.sd-input:focus {
    box-shadow: var(--sjs-shadow-inner-reset, inset 0px 0px 0px 0px rgba(0, 0, 0, 0.15)), 0 0 0 1px #004fc5 !important;
}

.sd-element__num {
    float: none !important;
}

.sd-header__text .sd-title {
    color: #004fc5 !important;
}

.spg-input:focus,
.spg-input.spg-dropdown:focus,
.spg-input.spg-dropdown:focus-within,
.spg-input-container:focus-within {
    box-shadow: inset 0 0 0 1px #004fc5 !important;
}

.spg-question__content {
    width: 100%;
    color: #004fc5 !important;
    cursor: pointer;
}

.sv-button-group:focus-within {
    box-shadow: 0 0 0 1px #004fc5 !important;
    border-color: #004fc5 !important;
}

/*---------------------------------------- SETTING CSS END ----------------------------------------*/
.my-custom-multiselect .multiselect-container {
    width: 300px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
}

.my-custom-multiselect .searchBox {
    border: 2px solid blue;
    border-radius: 5px;
}

.my-custom-multiselect .optionContainer {
    border: 2px solid green;
}

.my-custom-multiselect .chips {
    background: lightblue;
}

.searchWrapper {
    border-radius: 10px !important;
    background-color: #EDEEEE !important;
    padding-left: 20px !important;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: 100%;
}

.custom-dropdown {
    position: relative;
    cursor: pointer;
    border-radius: 10px !important;
    background-color: #EDEEEE !important;
    padding-left: 20px;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: 100%;
    align-content: center;
}

.selectdrop.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    display: none;
    z-index: 1000;
}

.selectdrop.dropdown-menu.show {
    display: block;
}

.selectdrop .dropdown-item {
    padding: 8px 12px;
    display: flex;
    align-items: center;
}

.selectdrop .dropdown-item label {
    margin-left: 8px;
    cursor: pointer;
}

.selectdrop .dropdown-item input[type='checkbox'] {
    cursor: pointer;
}

.selected-services {
    margin-bottom: 10px;
}

.selected-services ul {
    list-style-type: none;
    padding: 0;
}

.selected-services ul li {
    display: inline-block;
    background-color: #ededee;
    border-radius: 8px;
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    position: relative;
    font-size: 14px;
    color: #a6a7a9;
}

.selected-services ul li span {
    color: #a6a7a9;
}

.remove-btn {
    background: none;
    border: none;
    color: #ff0000;
    font-size: 16px;
    margin-left: 8px;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
}

.selected-value img {
    width: 10px;
    height: 9px;
}

.selectdrop {
    border: none !important;
    border-radius: 0px 0px 10px 10px;
}

.selectdrop input[type="checkbox"] {
    max-width: 18px !important;
    height: 18px !important;
}

.autocomplete-dropdown-container {
    background: #ffffff;
    border-radius: 0px 0px 8px 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 20px;
}

.suggestion-item--active,
.suggestion-item {
    color: #000;
    padding: 5px 12px;
    cursor: pointer;
}

.suggestion-item:hover,
.suggestion-item--active:hover {
    color: #004FC5;
}

/*---------------------------------------- INDIVIDUAL CLIENTS START ----------------------------------------*/
.tabs-section ul {
    border-bottom: 0px;
}

.tabs-section ul li {
    border: 1px solid #DCDCDC;
    color: #747475;
    border-radius: 50px;
    padding: 5px 20px;
    font-size: 18px;
    margin: 10px 10px 0px 0px !important;
}

.tabs-section ul .react-tabs__tab--selected {
    background: #000;
    color: #fff;
}

/*---------------------------------------- INDIVIDUAL CLIENTS END ----------------------------------------*/
/*---------------------------------------- RECURRING TASK START ----------------------------------------*/
.main-wrapper.edittask {
    height: 400px;
    overflow-y: scroll;
}

/*---------------------------------------- RECURRING TASK END ----------------------------------------*/
/*---------------------------------------- SURVEY FORM START ----------------------------------------*/
.tabs-contentmain {
    overflow: scroll;
}

.del-survey {
    float: right;
}

.add-btn-icon {
    position: relative;
    display: inline-block;
}

.add-new-button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    background: #004FC5;
    color: white;
    border: 1px solid #004fc5;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    height: 40px;
}

.divider-line {
    height: 14px;
    width: 1px;
    background-color: #FFFFFF;
    margin-left: 10px;
    opacity: 0.3;
    margin-right: 10px;
}

.add-btn-icon .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    width: auto;
    z-index: 1000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 10px !important;
    padding: 10px;
}

.add-btn-icon .dropdown div {
    padding: 5px 0px 0px 0px;
    cursor: pointer;
}

.add-btn-icon .dropdown div:hover {
    color: #004FC5;
}

.custom-select-container {
    width: 300px;
    position: relative;
    user-select: none;
}

.custom-select-display {
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
}

.custom-select-dropdown {
    position: absolute;
    width: 95%;
    background-color: #fff;
    border: none;
    max-height: 250px;
    overflow-y: auto;
    z-index: 1000;
    right: 10px;
    border-radius: 0px 0px 10px 10px;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.253) 0px 2px 20px;
    /* margin-right: 49px !important; */
}

.custom-select-search {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;
}

.custom-select-options {
    max-height: 160px;
    overflow-y: auto;
}

.custom-select-option {
    padding: 10px;
    cursor: pointer;
}

.custom-select-option:hover {
    background-color: #f1f1f1;
}

.custom-select-no-options {
    padding: 10px;
    color: #999;
}

.date-picker-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
}

.date-picker-input {
    width: 100%;
    padding-right: 30px;
}

.calendar-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #888;
    pointer-events: none;
}

.user-list {
    max-height: 350px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    z-index: 10;
    width: fit-content;
    background: red;
    border-radius: 10px;
    top: 50px;
    left: 50px;
}

.user-item {
    padding: 5px;
    cursor: pointer;
}

.user-item:hover {
    background-color: #b2c8ec29;
}

.gap10 {
    gap: 30px;
}

.nm-block {
    width: 40px;
    height: 40px;
    background: #b2c8ec;
    border-radius: 100px;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.nm-blocks img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 100%;
}

.nm-block p {
    font-size: 12px;
    color: #fff;
    font-weight: 600;
}

.nm-text h3 {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}

.nm-text p {
    font-size: 12px;
    color: #a79d9d;
    font-weight: 400;
}

.r-block p {
    font-size: 12px;
    color: #a79d9d;
    font-weight: 400;
}

.comm-scroll {
    max-height: 200px;
    overflow-y: scroll;
}

.comm-scrollLogs {
    max-height: 320px;
    overflow-y: scroll;
}

.logs-box {
    border: 1px solid #eaeaea;
    margin-top: 10px;
    width: fit-content;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
}

.logs-box img {
    width: 100px;
    height: 60px;
    max-width: 100px;
    object-fit: cover;
    border-radius: 8%;
}

.uploaded-image-preview {
    position: relative;
    width: 150px !important;
    height: 150px;
    padding-right: 0px !important;
}

.uploaded-image-preview .cross-pic {
    position: absolute;
    top: 5px;
    right: 5px;
}

.uploaded-image-preview img {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    object-fit: cover;
}

.toolbar {
    margin-bottom: 10px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.toolbar button {
    padding: 5px 10px;
    border: 1px solid #ddd;
    background-color: #f9f9f9;
    cursor: pointer;
    border-radius: 4px;
    margin: 2px;
}

.toolbar button:hover {
    background-color: #eaeaea;
}

.editor-container {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 4px;
    max-width: 600px;
    margin: 0 auto;
    min-height: 200px;
    position: relative;
}

.editor-input {
    outline: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    min-height: 100px;
}

.editor-placeholder {
    color: #aaa;
    position: absolute;
    pointer-events: none;
}

.picleft {
    width: 90px;
    height: 75px;
    object-fit: cover;
    border-radius: 10px;
}

/*---------------------------------------- 13-09-2024 ----------------------------------------*/
p br {
    display: none;
}

.bg-comment {
    background-color: #F0F3F9;
}

.cursor-pointer {
    cursor: pointer;
}

.pin-modal {
    background: #0000007d;
}

.operating-modal {
    background: #0000007d;
}

.taskadd .upload-wrapper {
    margin-right: auto;
}

.dottt {
    width: 5px;
    height: 5px;
    background: #747475;
    border-radius: 100%;
}

.logbox {
    border-radius: 10px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    gap: 8px;
}

.logbox img {
    width: 100px;
    height: 75px;
    border-radius: 10px;
    object-fit: cover;
}

.logbox h2 {
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
}

.add-new-button img {
    width: 16px;
    object-fit: contain;
    margin-right: 5px !important;
}

td.tableuser {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    gap: 8px;
}

.tableuser img {
    width: 28px;
    height: 28px;
    object-fit: cover;
}

td span.normal-badge {
    background: #D1E8D0;
    color: #00733B;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
}

td span.critical-badge {
    background: #CCDCF4;
    color: #024FC5;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
}

td span.minor-badge {
    background: #FFEECC;
    color: #FFAA27;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
}

td span.major-badge {
    background: #FED6D6;
    color: #E5252A;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
}

.statusbtn {
    width: auto;
    background: #F1F2F3;
    border-radius: 8px;
    padding: 6px 8px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
}

.statusbtn svg {
    width: -webkit-fill-available;
    max-width: fit-content;
    transform: rotate(180deg);
}

.neverexpires {
    margin-top: 28px !important;
    padding-left: 20px !important;
}

.mainshadow {
    box-shadow: rgb(0 0 0 / 7%) 0px 2px 20px;
}

.fullimage .modal-content .modal-header {
    border: none;
}

.fullimage .modal-content button.btn-close {
    filter: invert();
}

.fullimage .modal-content {
    background-color: transparent !important;
}

.fullimage .modal-content .modal-body img {
    width: 100% !important;
    height: 500px;
    object-fit: contain;
}

/*---------------------------------------- 16-09-2024 ----------------------------------------*/
.add-ind {
    margin: 0 auto !important;
}

.srchinp input {
    background: transparent !important;
    border: 1px solid #ccc !important;
}

.srchinp i {
    position: absolute;
    top: 0;
    left: 15px;
    bottom: 0px;
    align-content: center;
}

/*---------------------------------------- SURVEY FORM START ----------------------------------------*/
.svc-btn .svc-text,
.sv-button-group__item--selected,
.spg-matrixdynamic__add-btn {
    color: #004FC5 !important;
}

.svc-page__question-type-selector {
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.svc-tabbed-menu-item--selected {
    box-shadow: inset 0px -2px 0px var(--sjs-primary-backcolor, var(--primary, #004FC5)) !important;
}

.sv-action-bar-item--active .sv-action-bar-item__icon use {
    fill: #004FC5 !important;
}

.spg-search-editor_container {
    width: 100% !important;
    height: 50px !important;
}

.svc-side-bar__container-header,
.sd-title.sd-container-modern__title {
    box-shadow: inset 0px -2px 0px #004FC5 !important;
}

.spg-checkbox--checked .spg-checkbox__svg {
    fill: #004FC5 !important;
}

.spg-row.spg-clearfix {
    margin-top: 8px !important;
}

.sv-list__item.sv-list__item--selected>.sv-list__item-body,
.sv-list__item.sv-list__item--selected:hover>.sv-list__item-body,
.sv-list__item.sv-list__item--selected.sv-list__item--focused>.sv-list__item-body,
.sv-multi-select-list .sv-list__item.sv-list__item--selected.sv-list__item--focused>.sv-list__item-body,
li:focus .sv-list__item.sv-list__item--selected>.sv-list__item-body {
    background-color: #004FC5 !important;
}

.svc-page__footer {
    margin: 0px 24px !important;
}

.svc-string-editor:focus-within .svc-string-editor__border {
    box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1)), 0px 0px 0px calc(0.25*(var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-primary-backcolor, var(--primary, #004FC5)) !important;
}

.svc-btn:focus {
    box-shadow: 0 0 0 2px #004FC5 !important;
}

.sd-btn--action {
    background: #004FC5 !important;
}

.svc-page__footer .svc-btn {
    margin-top: 10px !important;
}

/*---------------------------------------- SURVEY FORM END ----------------------------------------*/
/*---------------------------------------- 19-09-2024 ----------------------------------------*/
div.arrowdrop {
    width: 40px;
    height: 30px;
    text-align: center;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: center;
}

tr.subtask-row,
tr.subtask-sub-row,
tr.subtask-sub-sub-row {
    box-shadow: none !important;
    background: #efefef !important;
}

.subtask-row td:nth-child(1) {
    position: relative !important;
    left: 20px;
}

.subtask-sub-row td:nth-child(1) {
    position: relative !important;
    left: 40px;
}

.subtask-sub-sub-row td:nth-child(1) {
    position: relative !important;
    left: 70px;
}

.subtask-sub-row td:nth-child(2) {
    position: relative !important;
    left: 20px;
}

.subtask-sub-sub-row td:nth-child(2) {
    position: relative !important;
    left: 30px;
}

tr.subtask-row td .statusbtn-new,
tr.subtask-sub-row td .statusbtn-new,
tr.subtask-sub-sub-row td .statusbtn-new {
    background: #ffffff;
}

.tableuser {
    position: relative;
}

.subtask-table {
    overflow: inherit;
    overflow-x: unset !important;
}

.subdrop {
    box-shadow: rgba(0, 0, 0, 0.253) 0px 2px 20px;
    max-width: 300px;
}

.subdrop ul,
.st-dropdown ul {
    box-shadow: none;
}

.st-dropdown {
    padding: 0px !important;
}

.st-dropdown ul li {
    padding: 4px 8px;
}

.st-dropdown ul li:hover {
    background-color: #d5e2f6;
}

.subdrop ul li {
    padding: 2px 8px;
}

.subdrop .srchinp {
    margin: 8px 8px 4px 8px !important;
}

.subdrop ul li:hover {
    background-color: #d5e2f6;
}

.subdrop ul li p {
    font-size: 14px;
}

input.custom-select-search {
    height: 40px !important;
    padding-left: 35px !important;
}

td.dropposition {
    position: relative;
}

.statusbtn-new {
    width: auto;
    background: #F1F2F3;
    border-radius: 8px;
    padding: 6px 8px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    gap: 15px;
    font-size: 14px;
}

.statusbtn-new svg {
    width: -webkit-fill-available;
    max-width: fit-content;
    transform: rotate(180deg);
}

.statusbtn-new svg.rot {
    transform: rotate(0deg);
}

.st-dropdown {
    display: none;
    position: absolute;
    left: 15px;
    right: 15px;
}

.st-dropdown.show {
    display: block;
    z-index: 99;
}

.tooltip-box {
    display: none;
}

.allname:hover .tooltip-box {
    display: block;
}

.slider-prev,
.slider-next {
    background: #000;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    border: 1px solid #000;
    max-width: 40px;
}

.comment-jodi .jodit-container:not(.jodit_inline) {
    background-color: #3e3ea9;
    border: none;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 4%) 0px 2px 20px;
}

/*---------------------------------------- 30-09-2024 ----------------------------------------*/
.search-drop select {
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    height: 32px !important;
    padding: 0px 10px;
    width: 160px;
    border-radius: 6px !important;
}

.searchuser {
    position: relative;
}

.searchuser input {
    padding-left: 26px;
    height: 32px !important;
    border-radius: 6px !important;
    font-size: 14px;
    width: 265px;
}

.searchuser i {
    position: absolute;
    left: 10px;
    font-size: 12px;
}

.tableuser thead th {
    border-bottom: 2px solid #fff;
    vertical-align: middle;
    padding: 2px 15px;
    white-space: nowrap;
    font-weight: 600;
    cursor: auto;
}

.tableuser tbody tr td:first-child {
    border: 1px solid #DCDCDC;
    height: 60px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: none;
}

.tableuser tbody tr td:last-child {
    border: 1px solid #DCDCDC;
    height: 60px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: none;
}

.tableuser tbody tr td {
    border-top: 1px solid #DCDCDC;
    border-bottom: 1px solid #DCDCDC;
    border-left: none;
    border-right: none;
    height: 60px;
    white-space: nowrap;
}

.roles input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
}

.roles label {
    font-size: 16px;
}

.input-grp {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    margin: 8px 0px;
}

/*---------------------------------------- SETTING ROLES ----------------------------------------*/
.settingpage {
    display: flex;
    display: -webkit-flex;
    gap: 15px;
}

.setting_left {
    width: 30%;
}

.setting_right {
    width: 70%;
}

.userdetailbox {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    gap: 15px;
}

.leftdetbox {
    width: 100%;
}

.rightdetbox {
    width: 0px;
}

.leftdetbox.shwdetail {
    width: 60%;
}

.rightdetbox.shwdetail {
    width: 40%;
}

.lhead img {
    width: 60px;
    height: 60px;
}

.lhead .emailicon {
    width: 14px;
    max-width: 14px;
    height: 14px;
}

.rhead select {
    /* background: #D1E8D0; */
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 14px;
    height: 28px;
    padding: 0px 20px 0px 10px;
    width: 100px;
}

.assignrole li {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    gap: 8px;
}

.assignrole li span {
    display: block;
    width: 6px;
    max-width: 6px;
    height: 6px;
    max-height: 6px;
    border-radius: 100%;
    background: #000;
}

.director-search {
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    border-radius: 0px 0px 10px 10px;
    border: none !important;
    border: none;
}

input.dtr-search {
    background-color: #fff !important;
}

.search-input i.dtr-icon {
    position: absolute;
    top: 13px;
    font-size: 14px;
}

input.dtr-srch {
    height: 50px !important;
    border: none;
    background-color: #edeeee !important;
    padding-left: 15px;
}

.dctor-search ul.bdr-none {
    border: none !important;
}

.dctor-search i.fa-search {
    position: absolute;
    left: 10px;
    top: 14px;
    font-size: 14px;
    color: #747475;
}

.dctor-search i.fa-times {
    position: absolute;
    right: 10px;
    top: 19px;
    font-size: 14px;
    color: #747475;
}

.dctor-search ul {
    padding: 0px;
    background: #fff;
    border: 1px solid #004FC51A;
    border-radius: 0px 0px 10px 10px;
}

.dctor-search ul li {
    font-size: 14px;
    margin: 8px 15px !important;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

.dctor-search ul li p {
    font-size: 12px;
    color: #a6a7a9;
}

.btn-lightblue.greenbtn {
    background-color: #D9EAE1 !important;
    color: #00733B !important;
}

.director-container .directortabs {
    border-radius: 6px;
    border: 1px solid #dcdcdc;
    padding: 0px 3px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    gap: 5px;
    margin: 10px 0px !important;
    height: 40px;
}

.director-container .directortabs button {
    font-size: 12px;
    color: #000;
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 7px 20px;
    border-radius: 6px;
}

.director-container .directortabs button.director-active {
    background-color: #000000;
    color: #ffffff;
}

.director-container .directortabs button:hover {
    background-color: #000000;
    color: #ffffff;
    transition: 0.5s;
}

.bg-none {
    background: none !important;
}

.permboxes {
    max-height: 470px;
    overflow-y: scroll;
}

.permboxes input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
}

.selectall {
    gap: 8px;
}

.selectall input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
}

.permtitle h6 {
    white-space: nowrap;
}

.perm-boxes ul {
    padding: 0px;
    display: flex;
    display: -webkit-flex;
    gap: 10px;
}

.perm-boxes ul li.act {
    border: none;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 15px;
    border-radius: 50px;
    color: #004FC5;
    background: #D9E5F7;
}

.perm-boxes ul li.deact {
    border: 1px solid #DCDCDC;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 15px;
    border-radius: 50px;
    color: #212529;
    background: none;
}

.permboxes li input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
}

.permboxes li label {
    font-size: 16px;
    color: #000;
}

.permboxes .permtitle {
    width: 15%;
}

.permboxes ul {
    width: 85%;
}

/*---------------------------------------- 02-10-2024 ----------------------------------------*/
.linkeddirector {
    max-height: 350px;
    overflow-y: scroll;
    padding: 0px;
}

.linkeddirectormain {
    max-height: 350px;
    overflow-y: scroll;
    padding: 0px;
}

.linkeddirector li {
    padding: 15px;
    border-radius: 20px;
    background-color: #F1F1F1;
    margin-bottom: 15px !important;
}

/*---------------------------------------- 04-10-2024 ----------------------------------------*/
.l_createpwd input[type="checkbox"] {
    width: 20px;
    height: 20px;
    max-width: 20px;
}

.pwdinput i {
    position: absolute;
    right: 15px;
    top: 22px;
}

.generatebtn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding-top: 25px;
}

/*---------------------------------------- 09-10-2024 ----------------------------------------*/
.dependdrop-box {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important;
    border-radius: 20px;
}

.dependant-drop {
    background: #F1F1F1;
    border-radius: 20px;
    padding: 15px;
    display: block;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
}

.dependant-drop.active {
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
}

.dependant-drop.active img.dropicn {
    transform: rotate(180deg);
}

.dependant-dropbox {
    background: #ffffff;
    border-radius: 0px 0px 20px 20px;
    padding: 0px 0px 15px 15px;
    display: none;
}

.dependant-dropbox.show {
    display: block;
}

/*---------------------------------------- 21-10-2024 ----------------------------------------*/
.taskround {
    position: absolute;
    top: -100px;
    left: -100px;
    display: none;
}

.checkbox__inner {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #626262;
    background: transparent no-repeat center;
    max-width: 18px;
}

.taskround:checked+.checkbox__inner {
    border-color: #000000;
    background-color: #000000;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='14px' height='10px' viewBox='0 0 14 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 59.1 (86144) - https://sketch.com --%3E%3Ctitle%3Echeck%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ios_modification' transform='translate(-27.000000, -191.000000)' fill='%23FFFFFF' fill-rule='nonzero'%3E%3Cg id='Group-Copy' transform='translate(0.000000, 164.000000)'%3E%3Cg id='ic-check-18px' transform='translate(25.000000, 23.000000)'%3E%3Cpolygon id='check' points='6.61 11.89 3.5 8.78 2.44 9.84 6.61 14 15.56 5.05 14.5 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 14px 10px;
}

.logsareamain {
    height: 400px;
    max-height: 400px;
    overflow-y: scroll;
    background: #fff;
    border-radius: 15px;
    padding: 15px;
    margin-top: 10px !important;
    margin-left: 10px !important;
}

/*---------------------------------------- 07-11-2024 ----------------------------------------*/
.newloghead i.showlogs {
    transform: rotate(90deg);
}

.newlogcontent,
.newloghead a {
    display: none;
}

.newlogcontent.showlogs,
.newloghead a.showlog {
    display: block;
}

/* 18-11-2024 */
.operatingbox {
    background-color: #F1F1F1;
    border-radius: 20px;
}

.uploadwrapbox {
    border: 1px solid #DCDCDC;
    border-radius: 2 0px;
}

/*-------------------------------------------------------------------------------- RESPONSIVE START --------------------------------------------------------------------------------*/

@media all and (max-width: 1480px) {
    .inside {
        padding: 20px !important;
    }

    .inside h4 {
        font-size: 28px;
    }

    .inside p {
        font-size: 16px;
        margin-top: 0px !important;
    }

    .inside svg {
        width: 60px;
    }
}

@media all and (max-width: 1280px) {
    .payment .left-payment {
        padding: 100px;
    }
}

@media all and (max-width: 1240px) {
    .main-form {
        width: 70% !important;
    }

    .formRight img.formtop {
        width: 120px;
        height: 120px;
        object-fit: contain;
    }
}

@media all and (max-width: 1200px) {
    .row.send-comment {
        left: 0px;
    }

    .right_notes .close_message_notes {
        display: none;
    }

    .add-invoice-area {
        margin-left: 0px;
    }
}


@media all and (max-width: 1199px) {
    .sidebar-main {
        left: -100%;
    }

    .sidebar-main.toggled {
        left: 0%;
        z-index: 9999;
    }

    .header-main,
    .right_section {
        left: 0;
    }

    .header-main i {
        display: block;
    }
}

@media all and (max-width: 1148px) {
    .write-icon {
        bottom: 120px;
    }
}

@media all and (max-width: 1100px) {
    .payment .left-payment {
        padding: 50px;
        order: 2;
    }
}

@media all and (max-width :1024px) {
    .assistlogo {
        top: 10px;
        left: 10px;
        max-width: 120px;
    }

    .task-info-box {
        text-align: center;
        justify-content: center;
        width: 100% !important;
    }

    .mobile-menu {
        display: none;
        position: absolute;
        left: -25px;
        top: 40px;
    }
}

@media all and (min-width: 991px) and (max-width: 1200px) {
    .select-text select {
        padding-left: 40% !important;
    }
}

@media all and (max-width :991px) {
    .form-page .formLeft {
        height: 350px !important;
    }

    .form-page .formLeft .left-text .text-bottom {
        position: inherit !important;
        padding-right: 0px !important;
    }

    .form-page .formLeft .left-text {
        text-align: left;
        height: auto !important;
    }

    .form-page .formLeft .left-text h2 {
        font-size: 36px;
    }

    .form-page .formLeft .left-text p {
        font-size: 16px;
        margin-top: 10px !important;
    }

    .forgotpassword .formRight {
        order: 2;
    }

    .forgotpassword .formLeft {
        height: auto !important;
    }

    .forgotpassword .lock-forgot {
        margin-bottom: 5px !important;
        width: 100px;
        margin-top: 50px;
    }

    .otpverify .formRight {
        order: 2;
    }

    .otpverify .formLeft {
        height: auto !important;
    }

    .otpverify .otpicon {
        margin-bottom: 5px !important;
        width: 100px;
        margin-top: 50px;
    }

    .right-header {
        flex-direction: inherit !important;
        justify-content: center !important;
    }

    .profile-box {
        height: fit-content;
        padding: 150px;
    }

    .p-detail-box {
        justify-content: center !important;
    }

    .left-prof {
        margin-bottom: 15px;
    }

    .right-prof ul {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .right-prof ul li {
        padding: 10px 0px;
    }

    .right-prof ul span {
        display: none;
    }

    .write-icon {
        bottom: 150px;
    }

    .payment {
        display: block;
    }

    .payment .left-payment,
    .payment .right-payment {
        width: 100%;
    }

    .payment .right-payment {
        position: relative;
    }

    .left-payment h2,
    .left-payment h1,
    .left-payment h4,
    .left-payment h6 {
        text-align: center;
    }
}

@media all and (max-width: 800px) {
    .formRight img.formtop {
        width: 80px;
        object-fit: contain;
        right: 3px;
        top: 3px;
    }

    .formRight img.formbottom {
        width: 80px;
        object-fit: contain;
        left: 3%;
    }

    .add-items-input {
        overflow-x: scroll;
        padding-bottom: 8px;
        width: fit-content;
    }
}

@media all and (max-width: 767px) {
    .client-detail-tabs .nav-tabs {
        justify-content: center;
    }

    .tab-content h4 {
        text-align: center;
    }

    .right_head {
        justify-content: center !important;
    }

    .client-tabs .nav-tabs {
        justify-content: center;
    }

    .right_head {
        justify-content: center !important;
    }

    .right_head ul {
        justify-content: center !important;
    }

    .task-detail-head .r_dethead ul {
        justify-content: flex-start !important;
    }

    .invoice-head-main {
        width: 100%;
        display: block;
        justify-content: space-between;
    }

    .invoice-head-main span {
        display: none;
    }

    .invoice-head-main .open,
    .invoice-head-main .draft,
    .invoice-head-main .overdue {
        text-align: center;
    }

    .about-info-box {
        display: flex;
        justify-content: space-between;
    }

    .task-info .task-info-box h4,
    m.task-info .task-info-box p {
        text-align: center !important;
    }

    .task-info .task-info-box span {
        margin: 0 auto !important;
    }
}

@media all and (max-width :600px) {
    .main-saform {
        border-radius: 40px;
        position: absolute;
        transform: none;
        left: 0;
        right: 0;
        margin: 0px 15px;
    }

    .main-saform .sub-button {
        font-size: 18px;
    }

    .superadmin-login .sa-row {
        margin: 20px;
    }

    .superadmin-login .main-saform {
        padding: 25px !important;
    }

    .form-page .formLeft {
        height: auto !important;
        padding: 20px 10px !important;
    }

    .form-head h2,
    .form-page .formLeft .left-text h2 {
        font-size: 28px;
    }

    .form-head h6,
    .main-form .sub-button {
        font-size: 18px;
    }

    .main-form label,
    .main-form input {
        font-size: 16px;
    }

    .left-text img {
        margin-bottom: 10px;
    }

    .header-main.dd .left-header {
        margin-bottom: 0px;
        margin-left: 8px;
    }

    .header-main .left-header h2 {
        text-align: center;
        font-size: 18px;
    }

    .header-main .right-header {
        justify-content: center;
    }

    .profile .user-name h4 {
        font-size: 16px;
    }

    .header-main.dd .mobile-menu {
        position: static;
        margin-right: 8px !important;
    }

    #menu-btn img {
        width: 25px !important;
    }

    #edit-profile h4 {
        font-size: 20px;
    }

    #edit-profile .btn-groups .btn {
        font-size: 16px;
        padding: 10px 15px;
        border-radius: 7px;
    }

    #change-password h4 {
        font-size: 20px;
    }

    #change-password .btn-groups .btn {
        font-size: 16px;
        padding: 10px 15px;
        border-radius: 7px;
    }

    .form-btns {
        display: block !important;
    }

    .form-btns button {
        margin: 7px 0px;
        width: 100%;
        text-align: center;
        justify-content: center;
    }
}

@media all and (max-width: 576px) {
    .task-detail-head .l_dethead {
        text-align: center;
    }

    .r_dethead ul {
        justify-content: center !important;
    }

    .task-detail-area {
        text-align: center;
        margin-top: 15px !important;
    }

    .task-pics {
        justify-content: center;
    }
}

@media all and (max-width: 520px) {
    .profile-box {
        height: 350px;
    }

    .imp-clients li {
        display: block !important;
    }

    .imp-clients .left-cl {
        justify-content: center;
    }

    .imp-clients .right-cl {
        text-align: center;
    }
}

@media all and (max-width: 480px) {
    .main-form {
        width: 100% !important;
        padding: 0px 20px;
    }

    .formRight img.formtop {
        display: none;
    }

    .formRight img.formbottom {
        display: none;
    }

    .forgotpassword .lock-forgot {
        width: 70px;
    }

    .otpverify .otpicon {
        width: 70px;
    }

    .header-main.dd {
        display: block !important;
    }

    .btn-clients-filter,
    .btn-active-inactive {
        display: flex;
        display: -webkit-flex;
        justify-content: center;
    }

    .btn-clients-filter button {
        font-size: 14px;
    }

    .uploading hr {
        margin: 0px 2px !important;
    }

    .invoice-head-main {
        display: block !important;
    }

    .invoice-head-main .open {
        margin: 15px 0px;
    }

    .left_head h4 {
        width: 100%;
        margin-bottom: 10px;
        text-align: center;
    }

    .invoice-area ul {
        display: block;
    }

    .invoice-area ul li {
        margin: 10px 0px;
        width: 100%;
    }

    .invoice-area li.nav-item button {
        width: 100%;
        justify-content: center;
    }

    .right-inv-head {
        justify-content: center;
    }
}


.upload-documents-container {
    margin: 20px;
    font-family: Arial, sans-serif;
}

.upload-documents-label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.upload-input-container {
    margin-bottom: 15px;
}

input[type="file"] {
    display: block;
    margin-bottom: 5px;
}

.upload-description {
    font-size: 12px;
    color: #6c757d;
}

.uploaded-files-list {
    margin-top: 20px !important;
    padding: 20px;
    /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
    border: 1px solid #DCDCDC;
    border-radius: 20px;
}

/* .file-upload-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
} */

.file-upload-item:last-child {
    border-bottom: none;
}

.file-info {
    /* display: flex;
    align-items: center;
    gap: 10px; */
    width: 100%;
}

.file-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 4px;
}

.file-name {
    color: #6c757d;
    font-size: 12px;
    text-overflow: ellipsis;
    width: 200px;
    word-wrap: break-word;
}

.file-size {
    font-size: 12px;
    color: #6c757d;
}

.progress-container {
    flex: 1;
    margin-left: 15px;
    margin-right: 15px;
}

.progress-container .rc-progress-line {
    height: 6px;
    width: 100%;
}

.progress-percentage {
    font-size: 12px;
    text-align: right;
    color: #6c757d;
}

.remove-file-icon {
    color: #dc3545;
    cursor: pointer;
    font-size: 18px;
}

/* NEW CLIENT */
.global-search input {
    padding-left: 25px;
    border: 1px solid #ccc !important;
    background: none !important;
    height: 40px !important;
}

.global-search i {
    position: absolute;
    left: 10px;
    top: 10px;
    bottom: 0px;
    align-content: center;
    font-size: 12px;
    color: #747475;
}

.search_dropbox p {
    font-size: 14px !important;
}

.input_search input {
    padding-left: 30px;
}

.input_search i {
    position: absolute;
    left: 10px;
    bottom: 0;
    top: 27px;
    font-size: 14px;
    color: #747475;
}




/*-------------------------------------------------------------------------------- RESPONSIVE END --------------------------------------------------------------------------------*/


.workprogress .tooltip-container {
    position: absolute;
    cursor: pointer;
    top: -30px;
    left: 0;
    right: 0;
    text-align: center;
}

.workprogress .tooltip-text {
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
    position: absolute;
    top: 0px;
    /* Adjust as per need */
    left: 50%;
    transform: translateX(-50%);
}

.col-lg-4:hover .workprogress .tooltip-text {
    opacity: 1;
    visibility: visible;
    cursor: pointer;
}

.active-indicator {
    background-color: green;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
}

.inactive-indicator {
    background-color: red;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
}

.status-indicator.inactive-indicator p {
    color: red !important;
}

/* Prevent drag cursor in status field */
.status-field {
    cursor: default !important;
    /* Ensure the default cursor is used */
}

.dropdown-menu.show {
    display: block;
    border-radius: 10px;
    border: 1px solid #efefef;
}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: transparent !important;
}

ul.listing_show {
    height: 200px;
    overflow-y: scroll;
    min-height: fit-content;
}

/* 31-12-2024 */
.close_modal {
    position: absolute;
    top: 0px;
    left: -22px;
}

.close_modal i {
    font-size: 22px;
}

.scroll_box {
    max-height: 700px;
    overflow-y: scroll;
}

.linked_box {
    background: #F1F1F1;
}

.contact_accordion .accordion-header {
    background-color: rgb(238 242 245) !important;
    border-radius: 8px;
    padding: 10px 15px !important;
    width: 100%;
    text-align: center;
    /* height: 40px; */
    display: flex;
    display: -webkit-flex;
    align-items: center;
    justify-content: space-between;
    border: none !important;
}

.contact_accordion .accordion-header.show-accordion {
    background-color: transparent !important;
    padding: 0px !important;
}

.contact_accordion .accordion-header p {
    display: block;
    text-align: left;
    line-height: normal;
}


.contact_accordion .accordion-header.show-accordion img {
    transform: rotate(180deg);
}

.contact_accordion .accordion-header h3 {
    font-size: 16px;
    font-weight: 600;
}

.contact_accordion .accordion-body {
    padding: 0px !important;
    border: none !important;
}

.light_input input {
    background: #fdfdfd !important;
    height: 40px !important;
}

.light_input textarea {
    background: #fdfdfd !important;
}

.form_checks input[type="checkbox"] {
    width: 16px !important;
    height: 16px !important;
}


button[data-tooltip] {
    position: relative;
}

button[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 100%;
    /* Change from bottom to top */
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    background-color: #333;
    color: white;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s;
    font-size: 12px;
    margin-top: 5px;
}

button[data-tooltip]:hover::after {
    opacity: 1;
}


li[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 100%;
    /* Places the tooltip below the button */
    left: 50%;
    transform: translateX(-50%);
    padding: 5px 10px;
    background-color: red;
    color: white;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s;
}

li[data-tooltip]:hover::after {
    opacity: 1;
}


/* 11-01-2025 */

/* NOTIFICATION PAGE CSS START */
.notification-tabs .nav-tabs {
    border: none;
    gap: 10px;
}

.notification-tabs .nav-tabs li button {
    border: 1px solid #dcdcdc;
    font-size: 18px;
    height: 40px;
}

.notification-tabs .nav-tabs li button.nav-link.active {
    color: #ffffff;
    padding: 13px 25px;
    height: 40px;
}

/* NOTIFICATION PAGE CSS END */

.client_table {
    max-height: 620px;
    overflow-y: scroll;
    padding-right: 10px;
}

.notif_table {
    border-spacing: 0px !important;
}

.notif_table th,
.notif_table td {
    padding: 5px;
    vertical-align: middle;
}

table.notif_table {
    border-collapse: collapse;
    width: 100%;
}

.notif_table th,
.notif_table td {
    border: 1px solid #DCDCDC;
    padding: 8px 15px;
}

.notif_table tbody {
    border: 1px solid #DCDCDC;
}

.notif_table .border_none {
    border: none;
}

.notif_table .border_left {
    border-left: none;
}

.notif_table .border_top {
    border-top: none;
}

.notif_table .border_right {
    border-right: none;
}

.notif_table .border_bottom {
    border-bottom: none;
}

.notif_table tr {
    box-shadow: none !important;
}

.notif_table tr th.ps-0:nth-child(1) {
    padding-left: 0px !important;
}

.br-0 {
    border-radius: 0px !important;
}

.brt-0 {
    border-radius: 0px;
}

.brb-0 {
    border-bottom-right-radius: 0px !important;
}

.brl-0 {
    border-radius: 0px;
}

.brr-0 {
    border-radius: 0px;
}

.brt-0 {
    border-top-right-radius: 0px !important;
}

.switch-field {
    display: table-cell;
    vertical-align: middle;
}

input.switch {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}

input.switch+label {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

input.switch+label,
input.switch+label:before,
input.switch+label:after {
    -webkit-border-radius: 2.4em;
    -moz-border-radius: 2.4em;
    -ms-border-radius: 2.4em;
    -o-border-radius: 2.4em;
    border-radius: 2.4em;
}

input.switch+label {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    width: 38px;
    height: 20px;
    overflow: hidden;
}

input.switch+label:before,
input.switch+label:after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    content: "";
}

input.switch+label:before {
    width: 38px;
    background-color: #DCDCDC;
    right: 0;
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    -o-transition: background 0.3s;
    transition: background 0.3s;
}

input.switch+label:after {
    background-color: #000000;
    width: 2.4em;
    width: 16px;
    height: 16px;
    -webkit-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.1);
    margin-top: 1px;
    -webkit-transition: margin 0.3s;
    -moz-transition: margin 0.3s;
    -o-transition: margin 0.3s;
    transition: margin 0.3s;
}

input.switch:checked+label:before {
    background-color: #004FC5;
}

input.switch:checked+label:after {
    margin-left: 20px;
    margin-top: 1px;
    background-color: #ffffff;
}

.client_table tbody tr {
    box-shadow: none;
}

.client_table tbody tr td {
    border: 1px solid #DCDCDC;
}

.client_table tbody tr td.inter_td {
    border: none;
}

.client_table thead tr th:nth-child(1) {
    padding-left: 0px !important;
}

.client_table tbody tr td:nth-child(1) {
    border-right: 0px;
    padding: 25px;
}

.client_table tbody tr td:nth-child(2),
.client_table tbody tr td:nth-child(3),
.client_table tbody tr td:nth-child(4) {
    border-left: 0px;
    border-right: 0px;
}

.client_table tbody tr td:nth-child(5) {
    border-left: 0px;
}

/* NOTIFICATION PAGE CSS END */

/* Task Status Css */
.open_status {
    background: #CCDCF4;
    color: #024FC5;
    width: fit-content;
    margin-left: auto !important;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 12px;
    display: block;
}

.inprogress_status {
    background: #D1E8D0;
    color: #00733B;
    width: fit-content;
    margin-left: auto !important;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 12px;
    display: block;
}


.reopen_status {
    background: #FFEECC;
    color: #FFAA27;
    width: fit-content;
    margin-left: auto !important;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 12px;
    display: block;
}


.fixed_status {
    background: #bcc3ff;
    color: #3730a3;
    width: fit-content;
    margin-left: auto !important;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 12px;
    display: block;
}

.incomplete_status {
    background: #5eead4;
    color: #0f766e;
    width: fit-content;
    margin-left: auto !important;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 12px;
    display: block;
}


.completed_status {
    background: #bef264;
    color: #65a30d;
    width: fit-content;
    margin-left: auto !important;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 12px;
    display: block;
}

.submitted_status {
    background: #7dd3fc;
    color: #0284c7;
    width: fit-content;
    margin-left: auto !important;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 12px;
    display: block;
}

.verified_status {
    background: #fbcfe8;
    color: #db2777;
    width: fit-content;
    margin-left: auto !important;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 12px;
    display: block;
}

.hold_status {
    background: #ffe488;
    color: #f99907;
    width: fit-content;
    margin-left: auto !important;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: 12px;
    display: block;
}

/* Task Status Css end */

/* AVATAR CSS START */
.avatar {
    width: 30px;
    height: 30px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    /* background-color: #2c303a; */
    border: 2px solid #2c303a;
}

.avatar img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.avatar-group {
    display: flex;
    margin-left: 8px !important;
}

.avatar-group.rtl {
    direction: rtl;
}

.avatar-group.rtl .avatar:hover:not(:last-of-type) {
    transform: translate(10px);
}

.avatar-group .avatar {
    margin-left: -10px !important;
    transition: transform 0.3s ease;
    cursor: pointer;
    background: #ccdcf4;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
}

.avatar-group .avatar:hover:not(:last-of-type) {
    transform: translate(-10px);
}

.avatar-group .hidden-avatars {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    margin-right: 23px;
    background-color: #2c303a;
    color: #fff;
}

/* AVATAR CS END */

/* TAG CSS START */
.tag_box {
    background-color: #EDEDEE;
    color: #000000;
    padding: 3px 10px;
    border-radius: 50px;
    font-size: 14px;
}

.tag_area {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    gap: 8px;
}

/* TAG CSS END */
.folder_hr .line {
    width: 1px;
    height: 135px;
    opacity: 1;
    background-color: #DCDCDC;
}

.folder_input input {
    background-color: #ffffff !important;
    border: 1px solid #D9D9D9;
}

.folder_input button {
    position: absolute;
    top: 12px;
    right: 5px;
    padding: 0px 22px !important;
}


/* FILES PAGE CSS START */
.folder_upload.main-wrapper {
    border: 1px dashed #212529;
    padding: 20px;
    border-radius: 10px;
}

/* FILES PAGE CSS END */


/* FILES CSS START */
.tr-none {
    display: none;
}

.tr-none.tr-show {
    display: block;
}

.td-shadow {
    box-shadow: rgb(0 0 0 / 7%) 0px 2px 20px;
}

.subrow-bg {
    background-color: #bfd1ee;
}

.files-bg {
    background-color: #88b0f1;
}

.pdf-bg {
    background-color: #F9C3C5;
}

.xls-bg {
    background-color: #BAD9C9;
}

.jpg-bg {
    background-color: #C1C3C4;
}

tr.clicked td svg.rotate {
    transform: rotate(90deg);
    transition: 0.5s;
}

.files_table tbody tr td {
    height: 50px;
}

/* FILES CSS END */

.complete-box {
    flex-wrap: wrap;
}

.complete-box img {
    border-radius: 50%;
}

.box_scroll {
    max-height: 520px;
    overflow-y: scroll;
}

.css-13cymwt-control {
    height: 50px !important;
    background: #edeeee !important;
    border: none !important;
    border-radius: 10px !important;
}

/* FILES CSS END */