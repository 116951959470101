@import url('https://fonts.cdnfonts.com/css/impact');
@import url('https://fonts.cdnfonts.com/css/poppins');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0px !important;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    font-family: 'Poppins', sans-serif;
    background-color: #f9fcff !important;
    /* user-select: none; */
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: separate !important;
    border-spacing: 0px 10px !important;
}

* {
    outline: none;
}

a,
a:hover {
    text-decoration: none !important;
    color: #003e71;
    cursor: pointer;
}

small {
    font-size: 12px;
}

img {
    max-width: 100%;
}

h1 {
    font-size: 36px;
    line-height: 44px;
}

h2 {
    font-size: 30px;
    line-height: 40px;
}

h3 {
    font-size: 26px;
    line-height: 35px;
}

h4 {
    font-size: 22px;
    line-height: 30px;
}

h5 {
    font-size: 18px;
    line-height: 26px;
}

h6 {
    font-size: 16px;
    line-height: 26px;
}

p {
    font-size: 16px;
    line-height: 26px;
}

li {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

:root {
    --main-color: #004FC5;
}

.font-impact {
    font-family: 'Impact', sans-serif !important;
}

.font-poppins {
    font-family: 'Poppins', sans-serif;
}

hr {
    border-color: #c1c1c1 !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.cursor-unset {
    cursor: unset !important;
}

.pac-container {
    z-index: 999999999;
}

.loadderr {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.offset-md-2 {
    margin-left: 16.66666667% !important;
}

.assignee_name {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    margin-left: 3px;
}

.breadcrumb {
    display: flex;
    align-items: center;
}

.scrollbox {
    width: 100%;
    height: -moz-fit-content;
    max-height: 400px;
    min-height: -moz-fit-content;
    min-height: 50px;
    overflow-y: scroll;
    /* height: 400px; */
    /* margin-top: 20px !important; */
}

.Toastify__toast-body {
    gap: 8px !important;
}

/*----------------------------- COLORS CSS -----------------------------*/
.text-blue {
    color: #004FC5;
}

.bg-blue-light {
    background-color: #b2c8ec;
}

.bg-light-blue {
    background: #D9E5F7;
    border-radius: 0px 0px 8px 8px;
}

.main-blue {
    background: var(--main-color);
}

.t_blue {
    color: #004FC5 !important;
}

.bg-gray-light {
    background-color: #EDEEEE !important;
}

.text--muted {
    color: #747475;
}

/*----------------------------- MODALS CSS -----------------------------*/
.modal-header {
    padding: 25px 25px 0px 25px !important;
    border: none !important;
}

.modal-header .close-icon {
    padding: 0px 3px 0px 0px !important;
}

.modal-header .btn-close {
    padding: 0px !important;
}

.modal-footer {
    padding: 22px 22px 25px 22px !important;
    border: none !important;
}

.modal-dialog-centered {
    margin: 0 auto !important;
}

/*----------------------------- FONTS CSS -----------------------------*/
.fs-8 {
    font-size: 8px;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-24 {
    font-size: 24px !important;
}

.fs-30 {
    font-size: 30px;
}

/* MARGIN CSS */
.ms_20 {margin-left: 20px !important;}
.ms_40 {margin-left: 40px !important;}
.ms_60 {margin-left: 60px !important;}
.ms_80 {margin-left: 80px !important;}
.ms_100 {margin-left: 100px !important;}

/*----------------------------- WIDTH CSS -----------------------------*/
.w-fit {
    width: fit-content !important;
}

.w_auto {
    width: auto !important;
}

.w-14 {
    width: 14px !important;
    max-width: 14px !important;
}

.w-18 {
    width: 18px !important;
}

.w-20 {
    width: 20px !important;
    max-width: 20px !important;
}

.w-24 {
    width: 24px !important;
}

.w_max {
    width: max-content;
}

/*----------------------------- HEIGHT CSS -----------------------------*/
.h_100vh {
    height: 100vh;
}

.h-au {
    height: 100%;
}

.h-14 {
    height: 14px !important;
    max-height: 14px !important;
}

.h-18 {
    height: 18px !important;
}

.h-20 {
    height: 20px !important;
    max-height: 20px !important;
}

.h-24 {
    height: 24px !important;
}

.h-40 {
    height: 40px !important;
}

.h_500 {
    height: 500px;
}

.h_800 {
    height: 800px;
}

.h_100vh {
    height: 100vh;
}

/*----------------------------- BADGES CSS -----------------------------*/
span.green-badge {
    background: #D1E8D0;
    color: #00733B;
    padding: 3px 8px;
    border-radius: 8px;
    display: fit-content;
    font-size: 14px;
}

span.blue-badge {
    background: #CCDCF4;
    color: #024FC5;
    padding: 3px 8px;
    border-radius: 8px;
    display: fit-content;
    font-size: 14px;
}

span.yellow-badge {
    background: #FFEECC;
    color: #FFAA27;
    padding: 3px 8px;
    border-radius: 8px;
    display: fit-content;
    font-size: 14px;
}

span.red-badge {
    background: #FED6D6;
    color: #E5252A;
    padding: 3px 8px;
    border-radius: 8px;
    display: fit-content;
    font-size: 14px;
}

.overdue-badge {
    color: #E5252A !important;
    background: #FED6D6 !important;
    border-radius: 100px !important;
    font-size: 14px !important;
    padding: 3px 10px !important;
    margin-top: 8px !important;
    width: fit-content !important;
    margin: 8px auto 0px auto !important;
}

.paid-badge {
    color: #00733B !important;
    background: #D1E8D0 !important;
    border-radius: 100px !important;
    font-size: 14px !important;
    padding: 3px 10px !important;
    margin-top: 8px !important;
    width: fit-content !important;
    margin: 8px auto 0px auto !important;
}

.draft-badge {
    color: #000 !important;
    background: #F1F2F3 !important;
    border-radius: 100px !important;
    font-size: 14px !important;
    padding: 3px 10px !important;
    margin-top: 8px !important;
    width: fit-content !important;
    margin: 8px auto 0px auto !important;
}

.respdata {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
}

/*----------------------------- TABLE CSS -----------------------------*/
/* table thead {
    background-color: #f1f2f5;
} */

th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

table tr td img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    object-fit: cover;
}

.table_part thead th {
    font-weight: 400;
    white-space: nowrap;
    color: #747475;
    padding: 10px 24px;
}

.table_part thead tr th:nth-child(1) {
    padding-left: 15px !important;
}

table tr td {
    border-bottom: 2px solid #fff;
    vertical-align: middle;
    padding: 8px 15px;
}

.table_part thead th {
    font-weight: 400;
    white-space: nowrap;
    color: #747475;
    padding: 10px 15px;
}

tr {
    cursor: pointer;
}

/*----------------------------- SCROLLBAR CSS -----------------------------*/

*::-webkit-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 20px;
    cursor: pointer;
    padding-left: 10px;
}

*::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e9e9e98f;
    cursor: pointer;
    padding-left: 10px;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cccccc7e;
    cursor: pointer;
    padding-left: 10px;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #cccccc;
    cursor: pointer;
    padding-left: 10px;
}

*::-webkit-scrollbar-thumb:active {
    background-color: #cccccc;
    cursor: pointer;
    padding-left: 10px;
}

*::-ms-scrollbar {
    height: 5px;
    width: 5px;
    border-radius: 20px;
    cursor: pointer;
    padding-left: 10px;
}

*::-ms-scrollbar-track {
    border-radius: 10px;
    background-color: #e9e9e98f;
    cursor: pointer;
    padding-left: 10px;
}

*::-ms-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cccccc7e;
    cursor: pointer;
    padding-left: 10px;
}

*::-ms-scrollbar-thumb:hover {
    background-color: #cccccc;
    cursor: pointer;
    padding-left: 10px;
}

*::-ms-scrollbar-thumb:active {
    background-color: #cccccc;
    cursor: pointer;
    padding-left: 10px;
}


/* ----------------------------- CUSTOM FILTER CSS ----------------------------- */
.custom-filter {
    border-radius: 10px !important;
    background: #000 !important;
    padding: 10px 12px;
    border: 1px solid #EDEEEE !important;
    height: 40px;
    width: 150px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.custom-filter h5 {
    font-size: 14px;
    color: #fff;
}

.custom-filter-open ul li {
    border-bottom: 1px solid #DCDCDC;
    padding: 10px 0px;
}

.custom-filter-open ul li:last-child {
    border: none;
}

.custom-filter-open {
    display: none;
    background: #fff;
    border-radius: 0px 0px 8px 8px;
}

.custom-filter-open ul {
    padding-left: 0px;
}

.custom-filter-open ul li {
    padding: 8px 12px;
}

.custom-filter-open ul li:hover {
    background: #BED4F0;
}

.custom-filter-open.show-custom {
    display: block;
    position: absolute;
    right: 0;
    left: 0;
}

.rotate.show-custom {
    transform: rotate(180deg);
}

/*----------------------------- SHADOWS CSS -----------------------------*/
.shadow-main {
    box-shadow: rgba(0, 0, 0, 0.253) 0px 2px 20px;
}

.shadow_main {
    box-shadow: #004FC514 0px 2px 20px;
}

.shadowmain {
    box-shadow: 0px 0px 20px #004FC514;
}

.shadow---main {
    box-shadow: rgb(0 0 0 / 10%) 0px 2px 20px;
}

/*----------------------------- BUTTONS CSS -----------------------------*/
.btn-gray {
    font-size: 16px !important;
    color: #000 !important;
    background: #ddd !important;
    font-weight: 600 !important;
}

.btn-lightblue {
    font-size: 16px !important;
    color: #004FC5 !important;
    background: #D9E5F7 !important;
    font-weight: 600 !important;
}

.btn-cancel {
    background: transparent;
    color: #000;
    border-radius: 10px;
    padding: 12px 20px;
    border: 1px solid #000;
    font-size: 16px;
}

li.nav-item button {
    border-radius: 50px !important;
    border: 1px solid #DCDCDC !important;
    color: #747475;
    font-size: 18px;
    padding: 13px 25px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    height: 40px;
    font-weight: 400;
}

li.nav-item button:hover {
    background: #000;
    color: #fff;
    border-color: #000;
    transition: 0.5s;
}

.nav-item button.active {
    background-color: #000;
    color: #fff;
}

/*----------------------------- MAIN FORM CSS -----------------------------*/
.main-form input {
    font-size: 18px;
    width: 100% !important;
    height: 60px;
    max-width: 100%;
    border: 1px solid #ccc;
    border-radius: 6px;
}

.main-form label {
    font-size: 18px;
}

.getotp h4 {
    font-size: 18px;
}

.main-form .otp_box input {
    font-size: 18px;
    width: 100% !important;
    height: 60px;
    max-width: 100%;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 0px 5px;
}

.file-form {
    background: #EDEDEE;
    border-radius: 8px;
    font-size: 14px;
    height: 30px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    padding: 5px 15px;
    gap: 5px
}

.main-form-area label {
    font-size: 18px;
    color: #9B9D9F;
    width: 100%;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    /* justify-content: center; */
}

/* .main-form-area label i,
.main-form-area label span {
    color: #ffffff;
    font-size: 16px;
} */

/*----------------------------- INPUT AND SELECT CSS -----------------------------*/
.custom-select-display.form-input-select {
    padding: 10px;
    padding-right: 30px;
    background-image: url('../src/assets/images/drop_down.png') !important;
    background-repeat: no-repeat, repeat !important;
    background-position: right .7em top 50%, 0 0 !important;
    background-size: .65em auto, 100% !important;
    border-radius: 10px !important;
    background: #EDEEEE;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: 100%;
}

input.form-input {
    border-radius: 10px !important;
    background: #EDEEEE !important;
    padding-left: 15px;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: 100%;
}

input.form-input-white {
    border-radius: 10px !important;
    background: #ffffff !important;
    padding-left: 15px;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: 100%;
}

button.form-input {
    border-radius: 10px !important;
    background: #EDEEEE !important;
    padding-left: 15px;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: 100%;
}

button.form-input.dropdown-toggle::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAKCAYAAACALL/6AAAABHNCSVQICAgIfAhkiAAAAMdJREFUGFeNkVEOwUAURe+riE/dgakYaf3oEiylO8AKxArUTuxE/aioxFuCforoM1NRjSC9yfu4kzlzb96Q8n3l3J2FQBSA0Ixrpq6LMQmBuGgVS/KGAUPQ+7j0yzJ5OrAvdBsCOfV1EAswbQIQsCalQpfaV26QksutowwEqEEwI8LqX4oI5nxK4xKw8rSfADT+DsnunB3sBlEBf6qVVZgTu5w3UFbTo5Ag21pKLqAJZ3uT/lSV8DpQ2o/MJ8XWCyHiY7qp13wA3yk7yOIjZQUAAAAASUVORK5CYII=);
    border: none;
    width: 10px;
    height: 8px;
    object-fit: cover;
    content: '';
    display: block;
    background-size: cover;
    top: 30px;
}


button.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    position: absolute;
    right: 15px;
    top: 32px;
}

input::placeholder {
    color: #747475 !important;
    font-size: 14px !important;
    font-weight: 400;
}
input[type="file"] {
    cursor: pointer;
} 
input[type="checkbox"] {
    width: 20px !important;
    height: 20px !important;
    accent-color: #000;
}

select {
    padding: 10px 30px 10px 10px;
    appearance: none;
    background-image: url('../src/assets/images/drop_down.png') !important;
    background-repeat: no-repeat, repeat !important;
    background-position: right .7em top 50%, 0 0 !important;
    background-size: .65em auto, 100% !important;
}

select option {
    text-transform: capitalize;
}

select.form-input {
    border-radius: 10px !important;
    background-color: #EDEEEE !important;
    padding-left: 20px;
    border: 1px solid #EDEEEE !important;
    height: 50px !important;
    width: 100%;
}

textarea {
    border-radius: 10px !important;
    background-color: #EDEEEE !important;
    border: 1px solid #EDEEEE !important;
    width: 100%;
    padding: 15px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background-image: url("../src/assets/images/calendar.svg");
    background-repeat: no-repeat;
    background-position: 100% center;
    background-size: cover;
    margin-right: 10px;
    cursor: pointer;
    width: 16px !important;
    height: 16px !important;
}

.select_on {
    width: 130px !important;
}


.sin_area {
    position: relative;
}

.sin_area input {
    padding-right: 40px;
}

.sin_icons {
    position: absolute;
    top: 22px;
    right: 15px;
    bottom: 0px;
}

.sin_icons i {
    cursor: pointer;
}

.text_ell {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text_ellnew {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}